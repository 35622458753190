<div class="container mt-5">
  <div class="row mb-5">
    <div class="col-lg-12">
      <img src="assets/img/vidoBoard-logo.svg"
           alt="vidoBoard by {{companyName}}"
           title="vidoBoard by {{companyName}}"
           class="login-logo img-fluid"/>
    </div>
  </div>
  <div class="row login-mt-welcome">
    <div class="col-lg-6">
      <h2 class="login-title">
        Welcome to {{title}} <br/>
        by {{companyName}}
      </h2>
      <p class="mt-4 mb-4">
        <strong>Please choose username</strong>
      </p>
      <form [formGroup]="loginForm" (ngSubmit)="submitUserData()">
        <div class="mb-3">
          <input
            type="text"
            class="form-control vvv-input-big"
            name="name"
            placeholder="Username"
            formControlName="name"
          >
          <div *ngIf="loginForm.controls['name'].invalid &&
                      loginForm.controls['name'].errors?.required && submitButtonTouched"
               class="vvv-input-big-error">
            Please chose a Username
          </div>
          <p class="mt-4 mb-4">
            <strong>Please choose a strong password</strong>
          </p>
          <div class="mb-3">
            <input
              type="password"
              class="form-control vvv-input-big"
              name="password"
              placeholder="Password"
              formControlName="password"
            >
            <div *ngIf="loginForm.controls['password'].invalid &&
                        loginForm.controls['password'].errors?.required && submitButtonTouched"
                 class="vvv-input-big-error">
              Please enter password
            </div>
          </div>
          <div class="mb-3">
            <input
              type="password"
              id=""
              name="passwordRepeat"
              class="form-control vvv-input-big"
              placeholder="Repeat Password"
              formControlName="passwordRepeat">
            <div *ngIf="loginForm.controls['passwordRepeat'].invalid &&
                        loginForm.controls['passwordRepeat'].errors?.required && submitButtonTouched"
                 class="vvv-input-big-error">
              Please enter password
            </div>
            <div *ngIf="loginForm.hasError('notSame')" class="vvv-input-big-error">
              Password does not match
            </div>
          </div>
            <button type="submit" class="
           btn
          btn-primary
          rounded-pill
          pr-4 pl-4"
                    (click)="clickSubmitButton()"
            >Submit
            </button>
        </div>
      </form>
    </div>
      <div class="col-lg-6">
        <img src="assets/img/201109_VVV_Header_erklarem-img.svg" alt="{{companyName}} Welcome"
             title="{{companyName}} Welcome" class="img-fluid login-welcome-asset"/>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
