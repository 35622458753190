import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input-type-ahead',
  templateUrl: './input-type-ahead.component.html',
  styleUrls: ['./input-type-ahead.component.sass']
})
export class InputTypeAheadComponent implements OnInit {

  @Input() data!: any;
  @Input() searchKeyWord!: string;
  // @Input() historyIdentifier!: string;
  @Input() placeHolder!: string;

  @Input() initialValue?: string;

  @Output() selectedEvent = new EventEmitter();

  @Input() disabled = false;

  public keyword = 'name';

  constructor() {
  }

  ngOnInit(): void {
  }

  selectEvent(event) {
    this.selectedEvent.emit(event);
  }

  onChangeSearch(inputChanged) {
    this.selectedEvent.emit(inputChanged);
  }

  onFocused(event) {
  }


}
