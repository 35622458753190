import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Product} from "../models/product";

const endpointGetProducts = environment.apiUrl + 'products/list';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient
  ) { }

  getProductList(): Observable<any> {
    return this.http.get<Product[]>(endpointGetProducts).pipe(
      tap(res =>  { return res; })
    );
  }
}
