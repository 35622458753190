import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {tap} from "rxjs/operators";

const endpointUploadFrame = environment.apiUrl + 'projectFiles/uploadFrame/--versionId--';
const endpointUploadVideo = environment.apiUrl + 'projectFiles/uploadVideo/--versionId--';
const endpointGetAllProjectAssetFiles = environment.apiUrl + 'projectFiles/getProjectFiles/--projectId--/--projectAssetId--/--versionId--';
const endpointUploadProjectFile = environment.apiUrl + 'projectFiles/uploadProjectAssetFile/--projectId--/--projectAssetId--/--tabId--';
const endpointUploadPublicFile = environment.apiUrl + 'projectFiles/uploadPublic/--projectId--/--projectAssetId--/--tabId--';
const endpointDeleteProjectAssetFile = environment.apiUrl + 'projectFiles/delete/--projectId--/--projectAssetFileId--';

interface fileUploadedResponse {
  status: string;
  fileUrl: string;
  workerTask_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectAssetFilesService {
  constructor(private http: HttpClient) {
  }

  public uploadFrame(
    projectId: string,
    projectAssetId: string,
    tabId: string,
    versionId: string,
    sceneId: string,
    frameId: string,
    data: any,
    fileName: string | undefined
  ) {

    const url = endpointUploadFrame.replace('--versionId--', versionId);
    return this.http.post<fileUploadedResponse>(url, {
      projectId,
      projectAssetId,
      tabId,
      versionId,
      sceneId,
      frameId,
      frame: data,
      fileName
    }).pipe(
      tap(res => res)
    );
  }

  public uploadVideo(
    projectId: string,
    projectAssetId: string,
    tabId: string,
    versionId: string,
    data: any
  ) {
    const url = endpointUploadVideo.replace('--versionId--', versionId);
    return this.http.post<fileUploadedResponse>(url, {
      projectId,
      projectAssetId,
      tabId,
      versionId,
      video: data
    }).pipe(
      tap(res=>res)
    );
  }

  public getAllProjectAssetFiles(
    projectId: string,
    projectAssetId: string,
    versionId: string
  ) {
    let url = endpointGetAllProjectAssetFiles.replace('--projectAssetId--', projectAssetId);
    url = url.replace('--versionId--', versionId);
    url = url.replace('--projectId--', projectId);
    return this.http.get<any>(url).pipe(
      tap(res => res)
    );
  }

  public uploadPublicFile(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    versionId: string,
    data: any
  ) {
    const url = endpointUploadPublicFile.replace('--projectAssetId--',projectAssetId);
    const url2 = url.replace('--projectId--', projectId);
    const url3 = url2.replace('--tabId--', projectAssetTabId);

    return this.http.post<any>(url3, {
      projectId,
      projectAssetId,
      tabId: projectAssetTabId,
      versionId,
      data
    }).pipe(
      tap(res=>res)
    );
  }

  public uploadProjectFile(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    versionId: string,
    data: any,
    type: string,
    fileName: string | undefined
  ) {

    const url = endpointUploadProjectFile.replace('--projectAssetId--',projectAssetId);
    const url2 = url.replace('--projectId--', projectId);
    const url3 = url2.replace('--tabId--', projectAssetTabId);

    return this.http.post<any>(url3, {
      projectId,
      projectAssetId,
      tabId: projectAssetTabId,
      versionId,
      data,
      type,
      fileName
    }).pipe(
      tap(res=>res)
    );
  }

  public deleteFile(
    projectId: string,
    projectAssetFileId: string
  ) {
    const url = endpointDeleteProjectAssetFile.replace('--projectId--', projectId);
    const url2 = url.replace('--projectAssetFileId--', projectAssetFileId);
    return this.http.delete(url2)
      .pipe(
            tap(res => res)
      );
  }


}
