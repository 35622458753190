import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {ProjectTemplate} from "../models/projectTemplate";

const endpointGetProjectTemplates = environment.apiUrl + 'projectTemplates/list';

@Injectable({
  providedIn: 'root'
})
export class ProjectTemplateService {

  constructor(
    private http: HttpClient
  ) { }

  getProjectTemplateList() {
    return this.http.get<ProjectTemplate[]>(endpointGetProjectTemplates).pipe(
      tap(res => res)
    );
  }
}
