<ul class="vido-user-menu-wrapper">
  <li class="nav-item dropdown"
      dropdown
      (onShown)="onShown()"
      (onHidden)="onHidden()"
      (isOpenChange)="isOpenChange()"
      placement="bottom left"
  >
    <a dropdownToggle (click)="false"
       class="nav-link dropdown-toggle"
       id="navbarDropdown"
       role="button"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false"
    >
      <span class="header-navigation-username-menu">{{user?.name}}</span>
    </a>
    <div id="basic-link-dropdown"
         *dropdownMenu
         class="dropdown-menu vido-user-menu vido-user-menu-dropdown-menu"
         role="menu"
         aria-labelledby="basic-link"
    >
      <div class="row">
        <div class="col-lg-4">
          <button class="btn-primary vvv-button-white-small vvv-button-small-usermenu" [routerLink]="['/']">Home</button>
        </div>
        <div class="col-lg-4">
          <button class="btn-primary vvv-button-white-small vvv-button-small-usermenu" [routerLink]="['/']">Projects</button>
        </div>
        <div class="col-lg-4">
          <button
            class="btn-primary vvv-button-white-small vvv-button-small-usermenu"
            (click)="addProject($event)"
            *ngIf="'create' | able: 'project'"
          >
            Add Projects
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <hr class="vido-user-menu-divider-hr pl-3 pr-3" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <button
            class="btn-primary vvv-button-white-small vvv-button-small-usermenu"
            *ngIf="'create' | able: 'projectAsset'"
          >
            Add Asset
          </button>
        </div>
        <div class="col-lg-4">
          <button
            class="btn-primary vvv-button-white-small vvv-button-small-usermenu"
            *ngIf="'list' | able: 'user'"
          >
            Users
          </button>
        </div>
        <div class="col-lg-4">
          <button
            class="btn-primary vvv-button-white-small vvv-button-small-usermenu"
            *ngIf="'create' | able: 'user'"
          >
            Invite Users
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <app-header-avatar></app-header-avatar>
        </div>
        <div class="col-lg-10">
          <div class="row">
            <div class="col-lg-12">Logged In As</div>
          </div>
          <div class="row">
            <div class="col-lg-12">{{user?.name}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12"></div>
      </div>
      <div class="row">
        <div class="col-lg-2"><button class="btn-primary vvv-button-white-small vvv-button-small-usermenu" (click)="logout($event)" >Logout</button></div>
        <div class="col-lg-2"><button class="btn-primary vvv-button-white-small vvv-button-small-usermenu" [routerLink]="['/users/profile']" >My Profile</button></div>
        <div class="col-lg-2" *ngIf="'create' | able: 'project'"><button class="btn-primary vvv-button-white-small vvv-button-small-usermenu" [routerLink]="['/library-admin']">Library</button></div>
      </div>
    </div>
  </li>
</ul>
