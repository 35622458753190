import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Comment} from "../../../models/comment";
import {takeUntil} from "rxjs/operators";
import {CommentService} from "../../../services/comment.service";
import {ToastrService} from "ngx-toastr";
import {CommentsRepository} from "../../../stores/comments/comments.repository";
import {Subject} from "rxjs";

@Component({
  selector: 'app-comment-inline-view',
  templateUrl: './comment-inline-view.component.html',
  styleUrls: ['./comment-inline-view.component.sass'],
})
export class CommentInlineViewComponent implements OnInit, OnDestroy {

  @Input() comment = new Comment();
  @Input() projectId!: string;
  @Input() projectAssetId!: string;
  @Output() hidePopOver = new EventEmitter();

  private ngUnsubscribe = new Subject();

  constructor(
    private commentService: CommentService,
    private toastrService: ToastrService,
    private commentsRepository: CommentsRepository
  ) { }

  ngOnInit(): void {
  }

  markCommentAsDone(comment) {
    this.commentService.updateCommentDoneStatus(comment).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Changed comment status');
        this.commentsRepository.updateSingleEntity(comment.id, 'done', !comment.done);
      },
      (error) => {
        this.toastrService.error('Error while saving comment');
      }
    );
  }

  deleteComment(comment: Comment) {
    if (confirm('Are you sure you want to delete this comment?')) {
      this.commentService.deleteComment(
        comment.id,
        this.projectId,
        this.projectAssetId
      ).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (success) => {
          // this.toastrService.success('Comment has been deleted');
          this.commentsRepository.deleteSingleEntity(comment.id);
        },
        (error) => {
          this.toastrService.error('Comment has not been deleted');
        }
      );
    }
  }

  hidePopover() {
    this.hidePopOver.emit(true);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
