<ng-container *ngIf="!imageUrl">
  <div class="custom-dropzone" ngx-dropzone [accept]="getAcceptedFiles()" (change)="onFilesAdded($event)">
    <ngx-dropzone-label *ngIf="!uploadRunning">
      <div>
        <div class="text-align-center">+</div>
        <h4>Drag &amp; Drop your file here</h4>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                *ngFor="let f of files" [file]="f" [removable]="true"
                                (removed)="onRemove(f)
      ">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
    <mat-spinner *ngIf="uploadRunning" [diameter]="35"></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="imageUrl">
  <div class="image-container-drop-zone">
    <img src="{{imageUrl}}" class="img-fluid" alt="Frame image" title="Frame image"/>
    <div class="overlay">
      <div class="text">
        <div class="d-flex flex-row align-items-stretch storyboard-hover-image h-100 w-100">
          <div class="flex-fill mouse-pointer border-right align-items-center d-flex">
            <div class="wrap w-100" (click)="openSketch()">
              <img src="assets/icons/icon-draw.svg" class="img-fluid" alt="Draw" title="draw">
              <div class="content">Draw</div>
            </div>
          </div>
          <div class="flex-fill mouse-pointer border-right align-items-center d-flex">
            <div class="wrap w-100">
              <img src="assets/icons/icon-upload.svg" alt="Upload" title="Upload">
              <div class="ontent">Upload</div>
            </div>
          </div>
          <div class="flex-fill mouse-pointer align-items-center d-flex" (click)="clickOnImageOpenModal()">
            <div class="wrap w-100">
              <img src="assets/icons/icon-detail.svg" alt="Detail" title="Detail">
              <div class="content">Detail</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showPlaceHolder">
  <div class="image-container-drop-zone">
    <img src="assets/library/placeholder1.jpg" class="img-fluid" alt="Frame image" title="Frame image"/>
    <div class="overlay">
      <div class="text">
        <ul class="storyboard-hover-image h-100 w-100 m-0 p-0">
          <li class="mouse-pointer">
            <img src="assets/icons/icon-draw.svg" class="img-fluid" alt="Draw" title="draw">
            <div class="content">Draw</div>
          </li>
          <li class="mouse-pointer">
            <img src="assets/icons/icon-upload.svg" alt="Upload" title="Upload">
            <div class="content">Upload</div>
          </li>
          <li class="mouse-pointer" (click)="clickOnImageOpenModal()">
            <img src="assets/icons/icon-detail.svg" alt="Detail" title="Detail">
            <div class="content">Detail</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
