import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommentService} from "../../../services/comment.service";
import {ToastrService} from "ngx-toastr";
import {Comment} from "../../../models/comment";
import {Observable, Subject} from "rxjs";
import {CommentsRepository} from "../../../stores/comments/comments.repository";
import {selectAllEntities} from "@ngneat/elf-entities";
import {takeUntil} from "rxjs/operators";

// import {CommentState} from "../../../stores/comments/comment.store";

@Component({
  selector: 'app-comments-storyboard-list',
  templateUrl: './comments-storyboard-list.component.html',
  styleUrls: ['./comments-storyboard-list.component.sass']
})
export class CommentsStoryboardListComponent implements OnInit, OnDestroy {

  @Input() frameId: string | undefined;
  @Input() reloadComments!: boolean;
  @Input() showComments: boolean = false;

  @Input() projectId!: string;
  @Input() projectAssetId!: string;
  @Input() versionId!: string;

  @Output() commentsCount = new EventEmitter();
  public commentsRepositoryComments: any;
  public loadingComments!: boolean;
  // public comments$: Observable<CommentState> = this.commentStore$.state$;

  public comments: Comment[] = [];

  private ngUnsubscribe = new Subject();

  constructor(
    private commentService: CommentService,
    private toastrService: ToastrService,
    public commentsRepository: CommentsRepository
  ) {
    // this.commentService.getCommentsForStoryBoard()
  }

  ngOnInit(): void {
    this.commentsRepositoryComments = this.commentsRepository.comments$.subscribe((comments) => {
      this.comments = comments;
      const currentCommentsCount = this.currentComments();
      this.commentsCount.emit(currentCommentsCount.length > 0);
    });

  }

  currentComments() {
    const comments = this.comments.filter((comment) =>
      comment.commentable_id === this.frameId
    );

    comments.sort((a, b) => {
      const aDate = new Date(a.created_at);
      const bDate = new Date(b.created_at);

      return bDate.getTime() - aDate.getTime();
    });

    return comments;

  }

  markCommentAsDone(comment) {
    this.commentService.updateCommentDoneStatus(comment).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Changed comment status');
        this.commentsRepository.updateSingleEntity(comment.id, 'done', !comment.done);
      },
      (error) => {
        this.toastrService.error('Error while saving comment');
      }
    );
  }

  filterScenesAndFrames() {
    return this.currentComments();
  }

  deleteComment(comment) {
    if(confirm('Are you sure you want to delete this comment?')) {

      this.commentService.deleteComment(
        comment.id,
        this.projectId,
        this.projectAssetId
      ).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (success) => {
          this.toastrService.success('Comment removed');
          this.commentsRepository.deleteSingleEntity(comment.id);
        },
        (error) => {
          this.toastrService.error('Error while removing comment');
        }
      )
    }
  }

  showCommentsForThisFrame() {
    return this.showComments;
  }
    ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
