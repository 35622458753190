import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {TabTemplate} from "../models/tabTemplate";

const endpointGetTabTemplates = environment.apiUrl + 'tabTemplates/list';
const endpointGetTabTemplatesAdmin = environment.apiUrl + 'tabTemplates/listAdmin';
const endpointDeleteTemplate = environment.apiUrl + 'tabTemplates/deleteTemplate/--templateId--';
const endpointAddTemplate = environment.apiUrl + 'tabTemplates/addTemplate';
const endpointUpdateTemplateActiveStatus = environment.apiUrl + 'tabTemplates/updateActiveStatusTemplate/--templateId--';
@Injectable({
  providedIn: 'root'
})
export class TabTemplateService {

  constructor(
    private http: HttpClient
  ) { }

  getTabTemplateList(templateTypes?: string[]) {
    return this.http.post<TabTemplate[]>(endpointGetTabTemplates,{
      templateTypes
    }).pipe(
      tap(res => res)
    );
  }

  getTabTemplateListAdmin() {
    return this.http.get<TabTemplate[]>(endpointGetTabTemplatesAdmin).pipe(
      tap(res => res)
    );
  }

  deleteTemplate(templateId: string) {
    const url = endpointDeleteTemplate.replace('--templateId--', templateId);
    return this.http.delete(url).pipe(
      tap(res => res)
    );
  }

  addTemplate(
    templateName,
    projectId,
    projectAssetId,
    projectAssetTabId,
    projectAssetTabVersionId
  ) {
    return this.http.post(endpointAddTemplate, {
      templateName,
      projectId,
      projectAssetId,
      projectAssetTabId,
      projectAssetTabVersionId
    }).pipe(
      tap(res => res)
    );
  }

  editTemplate(template) {
    return this.http.post(endpointAddTemplate, {template}).pipe(
      tap(res => res)
    );
  }

  changeTemplateActiveStatus(template) {
    const url = endpointUpdateTemplateActiveStatus.replace('--templateId--', template.id);
    return this.http.post(url, {}).pipe(
      tap(res => res)
    );
  }

}
