import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {Project} from "../models/project";

const endpointPinGetList = environment.apiUrl + 'pins/getListPinnedProjects/';
const endpointPinAddProject = environment.apiUrl + 'pins/addPinnedProject/--projectId--';
const endpointPinDelete = environment.apiUrl + 'pins/removePinnedProject/--projectId--';
const endpointGetPinListForProjectBoard = environment.apiUrl + 'pins/getPinnedProjectIds';
const endpointGetPinnedProjects = environment.apiUrl + 'pins/getPinnedProjects';

@Injectable({
  providedIn: 'root'
})
export class PinService {

  constructor(
    private http: HttpClient
  ) { }

  getPinnedList() {
    return this.http.get<Project[]>(endpointPinGetList).pipe(
      tap(res => res)
    );
  }

  addPin(projectId:string) {
    return this.http.post(endpointPinAddProject.replace('--projectId--', projectId), {}).pipe(
      tap(res => res)
    );
  }

  removePin(projectId) {
    const url = endpointPinDelete.replace('--projectId--', projectId);
    return this.http.delete(url).pipe(
      tap(res => res)
    );
  }

  getPinnedProjectIds() {
    return this.http.get<any>(endpointGetPinListForProjectBoard).pipe(
      tap(res => res)
    );
  }

  getPinnedProjects() {
    return this.http.get<any>(endpointGetPinnedProjects).pipe(
      tap(res => res)
    );
  }
}
