import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Ability} from "@casl/ability";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {tap} from "rxjs/operators";
import {User} from "../models/user";

const endpointGetByUserGroup = environment.apiUrl + 'users/listGroups';
const endpointChangePassword = environment.apiUrl + 'users/changePassword';
const endpointGetUserList = environment.apiUrl + 'users/list';
const endpointAddNewUser = environment.apiUrl + 'users/addUserToProject/--projectId--';
const endpointActivateUser = environment.apiUrl + 'users/activateAccount/--token--/--projectId--';
const endpointResendInvitation = environment.apiUrl + 'users/resendInvitation/--projectId--';
const endpointDeletePendingUser = environment.apiUrl + 'users/deletePendingUser/--projectId--';
const endpointRemoveUserFromProject = environment.apiUrl + 'users/removeUserFromProject/--projectId--';
const endpointSearchForUser = environment.apiUrl + 'users/searchForUser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private ability: Ability

  ) { }

  getUserListByUserGroup(userGroups: any): Observable<any> {
    return this.http.post(endpointGetByUserGroup, {"roleScope": userGroups}).pipe(
      tap(res =>  { return res; })
    );
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http.post(endpointChangePassword, {password: currentPassword, newPassword}).pipe(
      tap(res => res)
    );
  }

  getUserList(): Observable<any> {
    return this.http.get(endpointGetUserList).pipe(
      tap(res => res)
    );
  }

  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }

  addNewUser(userData, projectId) {
    const url = endpointAddNewUser.replace('--projectId--',projectId);
    return this.http.post(url, {userData}).pipe(
      tap(res => res)
    );
  }

  activateAccount(data, projectId, token) {
    const url  = endpointActivateUser.replace('--token--', token);
    const url2  = url.replace('--projectId--', projectId);
    return this.http.post(url2, {data}).pipe(
      tap(res => res)
    );
  }

  resendInvitation(user, projectId) {
    const url  = endpointResendInvitation.replace('--projectId--', projectId);
    return this.http.post(url, {userId: user.id}).pipe(
      tap(res => res)
    );
  }

  deletePendingUser(user,projectId) {
    const url  = endpointDeletePendingUser.replace('--projectId--', projectId);
    return this.http.post(url, {userId: user.id}).pipe(
      tap(res => res)
    );
  }

  removeUserFromProject(user,projectId) {
    const url  = endpointRemoveUserFromProject.replace('--projectId--', projectId);
    return this.http.post(url, {userId: user.id}).pipe(
      tap(res => res)
    );
  }

  searchForUsers(searchTerm) {
    return this.http.post(endpointSearchForUser, {searchTerm}).pipe(
      tap(res => res)
    );
  }

}
