  <header class="header-logged-in fixed-top bg-light-green text-white">
    <nav class="navbar navbar-expand-lg navbar-light sticky-top">
      <div class="container-fluid">
        <a class="navbar-brand text-white" [routerLink]="['/projects/']">
          <img src="assets/img/vidoboard-logo-small.svg" class="logo-small" alt="vidoBoard" title="vidoBoard">
        </a>
        <span class="mr-3 mt-1">&gt;</span>
        <a [routerLink]="['/projects']" class="link-home">Home</a>
        <ng-container *ngIf="projectName">
          <span class="ml-3 mr-3 mt-1">&gt;</span>
            <a>{{projectName}}</a>
        </ng-container>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll"
                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav mr-auto mt-1 text-white">
          </ul>
          <div class="navbar-nav mr-auto my-lg-1">
            <div class="btn-toolbar">
              <div class="btn-group">
                <button type="button" (click)="openModalProjectAdd()" *ngIf="'create' | able: 'project'"
                        class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font text-bold mr-2">
                  + Add project
                </button>
                <button type="button" *ngIf="'create' | able: 'project'" [routerLink]="['/projects/pinned']"
                        class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font text-bold mr-2">
                  Pinned projects
                </button>
              </div>
            </div>
            <div class="form-inline">
              <!-- todo: @Roland ng-typeahead einfügen -->
              <input class="form-control vvv-input-small mr-1"
                     type="search"
                     placeholder="SEARCH"
                     aria-label="Search"
                     [(ngModel)]="searchTerm"
                     (ngModelChange)="this.searchTermUpdate.next($event)"

              >
            </div>
          </div>
          <app-header-user-menu class="mr-2"></app-header-user-menu>
          <app-header-avatar></app-header-avatar>
        </div>
      </div>
    </nav>
  </header>
  <app-header-title></app-header-title>

