import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommentsRepository} from "../../../stores/comments/comments.repository";
import {CommentService} from "../../../services/comment.service";
import {FormControl, Validators} from "@angular/forms";
import {Subject, pipe} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-comment-add',
  templateUrl: './comment-add.component.html',
  styleUrls: ['./comment-add.component.sass']
})
export class CommentAddComponent implements OnInit, OnDestroy {

  public comments: Comment[] = [];
  public commentsRepositoryComments: any;

  @Input() projectId!: string;
  @Input() projectAssetId!: string;
  @Input() projectAssetTabId!: string;
  @Input() projectAssteTabVersionId!: string;
  @Input() sceneId!: string;
  @Input() frameNumber!: string;
  @Input() frameId!: string;
  @Input() userId!:string;
  @Input() currentTime?;
  @Input() isFrameComment = true;
  @Input() markerPosX: number = 0;
  @Input() markerPosY: number = 0;
  @Input() markerPosT: number = 0;
  @Input() markerPosRefWidth: any;
  @Input() markerPosRefHeight: any;
  @Input() videoId: string = '';
  @Input() inline = false;

  @Output() commentHasBeenSaved = new EventEmitter<boolean>();
  @Output() hidePopover = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  public content: string = '';
  public internal: boolean = false;

  constructor(
    private commentsRepository: CommentsRepository,
    private commentService: CommentService
  ) {
  }

  ngOnInit(): void {
    this.commentsRepositoryComments = this.commentsRepository.comments$.subscribe(
      (comments: any) => {
        this.comments = comments;
      }
    );
    const commentPostedSuccessfully = this.commentService.commentPostedSuccessfully.subscribe(
      (res) => {
          if(res) {
            this.content = '';
          }
      }
    );
  }

  addComment() {
    if(this.isFrameComment) {
      this.commentService.postCommentStoryBoardModalWithPosition(
        this.projectId,
        this.projectAssetId,
        this.projectAssetTabId,
        this.projectAssteTabVersionId,
        this.sceneId,
        this.frameId,
        this.userId,
        this.content,
        this.internal,
        this.frameNumber,
        this.markerPosX,
        this.markerPosY,
        this.markerPosRefWidth,
        this.markerPosRefHeight
      );
    }else{
      this.commentService.postCommentVideoWithPosition(
        this.projectId,
        this.content,
        this.internal,
        this.markerPosX,
        this.markerPosY,
        this.markerPosT,
        this.projectAssetId,
        this.projectAssetTabId,
        this.videoId,
        this.projectAssteTabVersionId
      )
        .pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(
        (success) => {
          this.commentHasBeenSaved.emit(true);
          this.content = '';
        },
        (error) => {
          this.commentHasBeenSaved.emit(false);
        }
      );
    }

  }

  closePopOver() {
    this.hidePopover.emit(true);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
