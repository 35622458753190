<div class="container-fluid pt-5 px-4">
  <div class="row header-bar mb-3">
    <div class="col-lg-4">
      <span class="frame-number">
        {{frameNumber}}
      </span>
    </div>
    <div class="col-lg-8">

      <div class="row">
        <div class="col-2">
          <div class="btn-light btn btn-sm lh-1" (click)="switchHideMarks()">
            <img src="/assets/icons/icon-eye.svg" alt="Hide marks" title="Hide marks"/>
            <span class="hide-marks">Hide Marks</span>
          </div>
        </div>
        <div class="col flex-grow-1 header-text-info text-color-white font-weight-bold text-center">
          Add comment by clicking the image
        </div>
        <div class="col-2 text-color-white text-right">
          <app-icon-close-rounded class="d-inline-block" (click)="bsModalRef?.hide()"></app-icon-close-rounded>
        </div>
      </div>
    </div>
  </div>
  <div class="row modal-rounded-borders mb-3">
    <div class="col-lg-4">
      <div class="bg-white pb-3 px-3">
        <div
          class="pt-2 pb-2 mx-n3 storyboard side-bar-title text-align-center">
          Comments
        </div>
        <div class="d-flex justify-content-center mt-2">
          <img src="/assets/icons/icon-comment-green.svg" alt="Comments" title="Comments"/>
        </div>
        <hr class="green-line">
        <ng-container *ngIf="loading">
          <mat-spinner [diameter]="35"></mat-spinner>
        </ng-container>
        <div class="comments-list scrollable-y storyboard-comment-height border-dashed-bottom pb-3">
          <ng-container *ngIf="!loading">
            <div class="my-3"
                 *ngFor="let comment of filterCommentsForCurrentFrame(); let index"
                 [ngClass]="{ 'comment-done': comment.done}"
            >
              <div class="row">
                <div class="col col-avatar">
                  <app-avatar [user]="comment.user" [sizeSmall]="true"></app-avatar>
                </div>
                <div class="col flex-grow-1 pl-0">
                  <div class="row align-items-center h-100">
                    <div class="col-lg-8 pl-1">
                      <p class="username-and-time"><span
                        class="text-bold">{{comment.user?.name}}</span> {{comment.created_at | date}}</p>
                    </div>
                    <div class="col-lg-4 text-right align-self-center">
                      <img src="/assets/icons/icon-not-done.svg" *ngIf="!comment.done"
                           alt="Mark this comment as done"
                           title="Mark this comment as done"
                           class="mouse-pointer"
                           (click)="markComment(comment)"
                      />
                      <img src="/assets/icons/icon-done.svg" *ngIf="comment.done"
                           alt="Mark this comment as done"
                           title="Mark this comment as done"
                           class="mouse-pointer"
                           (click)="markComment(comment)"
                      />
                      <img
                        src="/assets/icons/icon-delete.svg"
                        alt="Delete comment"
                        title="Delete comment"
                        class="mouse-pointer"
                        (click)="deleteComment(comment)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-0">
                <div class="col col-avatar">&nbsp;</div>
                <div class="col flex-grow-1 pl-0" [innerHTML]="comment.content">

                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <app-comment-add
            class="w-100"
            [projectId]="projectId"
            [projectAssetId]="projectAssetId"
            [projectAssetTabId]="projectAssetTabId"
            [projectAssteTabVersionId]="versionId"
            [frameNumber]="frameNumber"
            [frameId]="frameId"
          ></app-comment-add>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <button class="vvv-button-white-small" (click)="clickNextPrev('prev')">
            << prev frame
          </button>
        </div>
        <div class="col-6 text-right">
          <button class="vvv-button-white-small" (click)="clickNextPrev('next')">
            next frame >>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-8 text-align-center">
      <span class="image-container-marker">
        <div class="image-container" id="image-container" #imageContainer container="modal">
          <div class="image-boundary" #boundary (window:resize)="recalculateCurrentCommentPosition(getCommentsWithMarker())">
        <img class="img-fluid img-max-height storyboard-detail-view image-relative"
             src="{{imageUrl}}"
             alt="Frame Image"
             (click)="clickedOnImageAddMarker($event)"
             id="storyboard-image"
             #storyboardImage>
          <ng-container *ngFor="let commentWithMarker of getCommentsWithMarker()">
          <ng-container *ngIf="!hideMarks">
            <ng-template #popTemplate>
               <ng-container *ngIf="commentWithMarker.new" class="comment-inline-popup">
                <app-comment-add
                  [projectId]="projectId"
                  [projectAssetId]="projectAssetId"
                  [projectAssetTabId]="projectAssetTabId"
                  [projectAssteTabVersionId]="versionId"
                  [frameNumber]="frameNumber"
                  [markerPosX]="cursorX"
                  [markerPosY]="cursorY"
                  [markerPosRefWidth]="markerPosRefWidth"
                  [markerPosRefHeight]="markerPosRefHeight"
                  [frameId]="frameId"
                  [sceneId]="sceneId"
                  [isFrameComment]="true"
                  (hidePopover)="hidePopover()"
                  [inline]="true"
                ></app-comment-add>
               </ng-container>
               <ng-container *ngIf="commentWithMarker.id && !commentWithMarker.new">
                 <app-comment-inline-view
                   [comment]="commentWithMarker"
                   [projectId]="projectId"
                   [projectAssetId]="projectAssetId"
                   (hidePopOver)="hidePopover()"
                 >
                 </app-comment-inline-view>
               </ng-container>
            </ng-template>
                <ng-container *ngIf="!commentWithMarker.done">
                  <img src="/assets/icons/icon-comment-undone.svg"
                       [popover]="popTemplate"
                       #pop="bs-popover"
                       (click)="openPopover(pop)"
                       container="body"
                       class="comment-mark"
                       alt="Comment not resolved yet"
                       title="Comment not resolved yet"
                       [ngStyle]="getCurrentCommentPosition(commentWithMarker)"
                       cdkDrag
                       (cdkDragStarted)="disableAddNewComment($event)"
                       (cdkDragEnded)="commentDropped($event, commentWithMarker)"
                       (onShown)="disableAddNewComment($event)"
                       (onHidden)="enableAddNewComment($event)"
                       cdkDragBoundary=".image-boundary"
                       id="stroyboard-image"
                  />
                </ng-container>
                <ng-container *ngIf="commentWithMarker.done">
                  <img src="/assets/icons/Icons_CommentGreen.svg" *ngIf="commentWithMarker.done"
                       [popover]="popTemplate"
                       #pop="bs-popover"
                       (click)="openPopover(pop)"
                       class="comment-mark"
                       alt="Comment not resolved yet"
                       title="Comment not resolved yet"
                       [ngStyle]="getCurrentCommentPosition(commentWithMarker)"
                       cdkDrag
                       (cdkDragEnded)="commentDropped($event, commentWithMarker)"
                       cdkDragBoundary=".image-boundary"
                       id="stroyboard-image"
                  />
                </ng-container>
            </ng-container>
          </ng-container>
          </div>
        </div>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4">
    </div>
    <div class="col-lg-8 text-color-white">
      <div>
        <tabset>
          <tab heading="Script" *ngIf="frame.script">{{frame.script}}</tab>
          <tab heading="Annotation" *ngIf="frame.anm">{{frame.anm}}</tab>
          <tab heading="Col 3" *ngIf="frame.col3">{{frame.col3}}</tab>
          <tab heading="Col 4" *ngIf="frame.col4">{{frame.col4}}</tab>
          <tab heading="Col 5" *ngIf="frame.col5">{{frame.col5}}</tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
