<app-modal-green-header-large [modalTitle]="'Add ProjectAsset Template'"></app-modal-green-header-large>
<div class="modal-body pt-0">
  <hr>
  <div class="form-group col-lg-12">
    <label class="modal-form-label">Template name</label>
    <input [(ngModel)]="templateName"
           name="templateName"
           type="text"
           class="form-control vvv-input-big"
    >
    <button (click)="createTemplateFromProject()"
            class="vvv-input-medium btn btn-primary background-white text-vvv-green-main text-bold ml-2">
      + Save
    </button>
  </div>
</div>
