import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public showBackground = false;
  public bigTitleInHeader = false;
  public titleInHeader = new BehaviorSubject('Projects');
  public showTabsInHeader = new BehaviorSubject(false);
  public showBigTitleInHeader = new BehaviorSubject(true);
  public activeProjectAssetTabId = new BehaviorSubject('');
  public containerFluid?: boolean = false;
  public projectAssetId?: string;

  constructor() {
  }

  setTitle(title: string) {
    this.titleInHeader.next(title);

  }

  resetTitle() {
    this.titleInHeader.next('');
  }

  setBackground(backgroundValue: boolean) {
    this.showBackground = backgroundValue;
  }

  setContainerFluid(value: boolean) {
    this.containerFluid = value;

  }

  resetContainerFluid() {
    this.containerFluid = false;
  }

  addProjectAssetId(projectAssetId: string) {
    this.projectAssetId = projectAssetId;
  }

  addProjectAssetTabId(projectAssetTabId: string) {
    this.activeProjectAssetTabId.next(projectAssetTabId);
  }

  setShowTabsInHeader(value) {
    this.showTabsInHeader.next(value);
  }

  setShowBigTitleInHeader(value) {
    this.showBigTitleInHeader.next(value);
  }



}
