import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ProjectTemplate} from "../models/projectTemplate";
import {tap} from "rxjs/operators";

const endpointGetClients = environment.apiUrl + 'clients/list';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient
  ) { }

  getClientList() {
    return this.http.get<ProjectTemplate[]>(endpointGetClients).pipe(
      tap(res => res)
    );
  }
}
