<table class="table text-light-grey">
  <thead>
  <th scope="col">
  </th>
  <th scope="col"></th>
  <th scope="col">
    Pin
  </th>
  <th scope="col">
    Project#
  </th>
  <th scope="col">
    Link#1
  </th>
  <th scope="col">
    Link#2
  </th>
  <th scope="col">
    Sales
  </th>
  <th scope="col">
    Project Manager
  </th>
  <th scope="col">
    Deadline
  </th>
  <th scope="col">
    Share-Link
  </th>
  <th scope="col">
  </th>
  </thead>
  <ng-container *ngFor="let project of projects">
    <tr [ngClass]="{'assets-open' : showProjectAssets(project.id) == true}">
      <td>
        <ng-container *ngIf="project.project_assets?.length">
          <div class="text-vvv-green-main icon-rounded-circle text-center mouse-pointer"
               (click)="toggleDisplayOpenendProjectAssetsOfProject(project.id)">
            <img src="/assets/icons/expand_more_24px_white.svg"
                 class="text-color-white icon-expand "
                 alt="expand more"
                 title="expand more">
          </div>
        </ng-container>
        <ng-container *ngIf="!project.project_assets?.length">
          <!--          <div class="text-vvv-green-main icon-rounded-circle text-center">-->
          <!--            <img src="/assets/icons/expand_more_24px_white.svg"-->
          <!--                 class="text-color-white icon-expand "-->
          <!--                 alt="expand more"-->
          <!--                 title="expand more">-->
          <!--          </div>-->
        </ng-container>
      </td>
      <td class="text-medium-font">
        <div class="text-medium-font">
          <small>{{project.client?.name}}</small>
        </div>
        <a routerLink="/projects/detail/{{project.id}}" class="text-dark"><strong>{{project.name}}</strong></a>
      </td>
      <td>
        <ng-container *ngIf="!checkIfIsPinned(project.id)">
          <img src="/assets/icons/icon-unpinned.svg" class="mouse-pointer" alt="Bookmark" title="bookmark"
               (click)="addAsPinned(project.id)">
        </ng-container>
        <ng-container *ngIf="checkIfIsPinned(project.id)">
          <img src="/assets/icons/icon-action-bookmark_24px.svg" class="mouse-pointer" alt="Bookmark" title="bookmark"
               (click)="removePinned(project.id)">
        </ng-container>
      </td>
      <td>{{project.teamboxId}}</td>
      <td>
        <ng-container *ngIf="project.link1">
          <a href="{{project.link1}}" target="_blank">
            <img src="/assets/icons/icon-moco.svg" alt="moco" title="Moco" class="mouse-pointer dashboard-icon"/>
          </a>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="project.link2">
          <a href="{{project.link2}}" target="_blank">
            <img src="/assets/icons/icon-hive.svg" alt="hive" title="Hive" class="mouse-pointer dashboard-icon"/>
          </a>
        </ng-container>
      </td>
      <td>{{project.seller?.name}}</td>
      <td>{{project.pm?.name}}</td>
      <td>{{project.deliveryDate}}</td>
      <td></td>
    </tr>
    <ng-container *ngIf="showProjectAssets(project.id)">
      <ng-container *ngFor="let asset of project.project_assets">
        <tr>
          <td>
            <div class="ml-3">
              <img src="/assets/icons/icon-video-placeholder.svg" alt="placeholder" title="placeholder"/>
            </div>
          </td>
          <td class="text-medium-font">
            <div class="ml-3">
              {{asset.product?.name}}
              <ng-container *ngIf="asset.project_asset_tabs && asset.project_asset_tabs.length > 0">
                <div class="text-dark">
                  <ng-container
                    *ngIf="asset.project_asset_tabs[0].config?.type && asset.project_asset_tabs[0].config?.type == 'storyboard'">
                    <a [routerLink]="[
                    '/projects',
                    'assets',
                    'storyboard',
                    asset.project_id,
                    asset.id,
                    asset.project_asset_tabs[0].id,
                    asset.project_asset_tabs[0].versions[0].id
                  ]" class="text-dark">{{asset.name}}</a>
                  </ng-container>
                  <ng-container
                    *ngIf="asset.project_asset_tabs[0].config?.type && asset.project_asset_tabs[0].config?.type == 'files'">
                    <a [routerLink]="['/projects',
                  'assets',
                  'files',
                  asset.project_id,
                  asset.id,
                  asset.project_asset_tabs[0].id,
                  asset.project_asset_tabs[0].versions[0].id
                  ]" class="text-dark">{{asset.name}}</a>
                  </ng-container>
                  <ng-container
                    *ngIf="asset.project_asset_tabs[0].config?.type && asset.project_asset_tabs[0].config?.type == 'video'">
                    <a [routerLink]="['/projects',
                  'assets',
                  'video',
                  asset.project_id,
                  asset.id,
                  asset.project_asset_tabs[0].id,
                  asset.project_asset_tabs[0].versions[0].id
                  ]" class="text-dark">{{asset.name}}</a>
                  </ng-container>
                </div>
                <div class="text-medium-font">
                  <small>{{asset.products.name}}</small>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!(asset.project_asset_tabs[0]?.config?.type && asset.project_asset_tabs[0].config?.type == 'video')
                  && !(asset.project_asset_tabs[0]?.config?.type && asset.project_asset_tabs[0].config?.type == 'files')
                  && !(asset.project_asset_tabs[0]?.config?.type && asset.project_asset_tabs[0].config?.type == 'storyboard')">
                <div class="text-dark">
                  <a class="text-dark" [routerLink]="[
                    '/projects',
                    'assets',
                    asset.project_id,
                    asset.id,
                    'settings'
                ]"
                  >{{asset.name}}</a>
                </div>
                <div class="text-medium-font">
                  <small>{{asset.products.name}}</small>
                </div>
              </ng-container>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="h-100">
            <button
                  class="btn-link-copy"
                  tooltip="Link copied"
                  triggers="focus"
                  placement="right"
                  (click)="copyLinkToClipboard('/projects/assets/'+asset.project_id +'/'+asset.id+'/settings')"
            >
            <img src="/assets/img/icon-social-share_24px.svg"
                 class="mx-auto my-auto d-block mouse-pointer"
                 alt="Copy Link"
                 title="Copy Link"
            >
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</table>
