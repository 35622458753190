<div class="container-fluid mt-5">
  <div class="row">
    <div class="col-lg-3">
      <h2>Library</h2>
    </div>
    <div class="col-lg-7">
      <h2>Detail</h2>
    </div>
    <div class="col-lg-2">
      <h2>Infos</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3">
      <input type="text" name="search" placeholder="Search" disabled class="mb-5">
      <ul *ngFor="let library of libraries">
        <li>{{library.name}}</li>
        <ng-container *ngIf="library.children.length > -1">
          <ul class="container-library-images mt-3">
            <li *ngFor="let category of library.children">
              {{category.name}}
              <ng-container *ngIf="category.items.length > -1">
                <ul>
                  <li *ngFor="let item of category.items">
                    <div>
                      <div class="row mouse-pointer library-item-wrapper" (click)="loadDetailOfItem(item, category)">
                        <div class="col-lg-12">
                          <img class="img-fluid library-item" src="{{item.link}}" alt="" title="">
                          <p>{{item.name}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">

                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-container>
            </li>
          </ul>
        </ng-container>
      </ul>
      <div class="row mt-5">
        <div class="col-lg-12"><h2>Upload</h2></div>
      </div>
      <ng-container *ngIf="!uploadRunning">
        <form [formGroup]="formData">
          <div class="form-group">
            <label>Choose category</label>
            <select class="form-control" formControlName="libraryCategoryId">
              <optgroup *ngFor="let library of libraries" label="{{library.name}}">
                <option *ngFor="let category of library.children" value="{{category.id}}">
                  {{category.name}}
                </option>
              </optgroup>
            </select>
          </div>
          <div class="form-group">
            <label>Filename</label>
            <input class="form-control" type="text" name="fileName" autocomplete="off" formControlName="fileName">
          </div>
          <div class="form-group">
            <label>Tags</label>
            <input class="form-control" type="text" name="description" autocomplete="off" formControlName="description">
          </div>
          <div class="form-group">
            <label>File</label>
            <input class="form-control-file" type="file" name="file" formControlName="data">
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" name="fastlib" formControlName="fastlib">
            <label class="form-check-label">Add to FastLib</label>
          </div>
          <div class="row mt-3">
            <div class="col-lg-12">
              <button (click)="uploadFileToLibrary()"
                      class="btn
                      btn-primary
                      rounded-pill
                      pr-4 pl-4">
                Upload
              </button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="uploadRunning">
        <mat-spinner [diameter]="35"></mat-spinner>
      </ng-container>
    </div>
    <div class="col-lg-7">
      <ng-container *ngIf="detail">
        <div class="row">
          <div class="col-lg-12">
            <img src="{{detail.link}}"
                 alt="{{detail.name}}"
                 title="{{detail.name}}"
                 class="img-fluid"
            >
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-12 text-align-center">
            <a href="{{detail.link}}"
               class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font mr-3"
               target="_blank">Download</a>
            <a class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font mr-3">Upload
              New</a>
            <a class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font"
               (click)="deleteItem(detail)">Delete</a>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-lg-2">
      <ng-container *ngIf="detail">
        <div class="row">
          <div class="col-lg-12">
            Uploaded by
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">

          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            Type:
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            Size
          </div>
        </div>
        <form [formGroup]="detailInformationFormGroup">
          <div class="row mt-5">
            <div class="col-lg-12">
              <h3>Tags</h3>
              <input type="text" value="{{detail.description}}" formControlName="description">
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12">
              <h3>Name</h3>
              <input type="text" value="{{detail.name}}" formControlName="name">
            </div>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="checkbox" [(ngModel)]="detail.fastlib" formControlName="fastlib">
            <label class="form-check-label">Available in fastlib</label> {{detail.fastlib}}
          </div>
          <div class="form-group mt-3">
            <button class="btn btn-primary rounded-pill pr-4 pl-4"
                    (click)="submitLibraryChanges(detail)">Save
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>

