<a (click)="doUserLogout()">Logout</a>
<div class="container">
  <div class="row">
    <div class="col-lg-2">
      Avatar
    </div>
    <div class="col-lg-3">
      <div class="col-lg-12">
        <h4>Name</h4>
        {{user?.name}}
      </div>
      <div class="col-lg-12 mt-5">
        <h4>Password</h4>
        ********
      </div>
      <div class="col-lg-12 mt-5">
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-lg-12">
        <h4>Company</h4>
        {{user?.client?.name}}
      </div>
      <div class="col-lg-12 mt-5">
        <button class="btn-primary vvv-button-white" (click)="resetPasswordModal()">
          Reset Password
        </button>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-lg-12">
        <h4>Email</h4>
        {{user?.email}}
      </div>
    </div>
    <div class="col-lg-2"></div>
  </div>
</div>
