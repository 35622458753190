import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {takeUntil, tap} from "rxjs/operators";
import {Frame} from "../models/frame";

const endpointAddFrameToStoryBoard = environment.apiUrl + 'frames/addFrameToSceneInStoryboard';
const endpointAdd10FramesToStoryBoard = environment.apiUrl + 'frames/add10FramesToSceneInStoryboard';
const endpointDeleteFrame = environment.apiUrl + 'frames/deleteFrame/--frameId--';

const endpointCopyExistingFrameInStoryBoard = environment.apiUrl + 'frames/copyFrameOnServer/--frameId--';

const endpointGetFramesOfLastVersion = environment.apiUrl + 'frames/getLatestFrames/--projectAssetId--/--projectTabId--';
const endpointUpdateFrameOrderInOneScene = environment.apiUrl + 'frames/UpdateFrameOrderInOneScene/--projectId--/--projectAssetId--/--projectAssetTabId--/--projectAssetTabVersionId--';
const endpointUpdateFrameOrderInAllScenes = environment.apiUrl + 'frames/UpdateFrameOrderInAllScenes/--projectId--/--projectAssetId--/--projectAssetTabId--/--projectAssetTabVersionId--';

const endpointNextFrameInStoryBoard = environment.apiUrl + 'frames/nextFrame/--frameId--/--direction--';

const endpointAddFrameFromLibarary = environment.apiUrl + 'frames/addFrameToSceneInStoryBoardFromLibrary/--projectId--/--projectAssetId--/--projectTabId--/--versionId--';



interface frameOrdering {
  id: any,
  ordering: any,
  frameNumber: any
  scene_id?: any,
  frames?: any
}

interface sceneData {
  data: any;
}


@Injectable({
  providedIn: 'root'
})
export class FrameService {

  constructor(
    private http: HttpClient
  ) {
  }

  add10FramesToScene(
    project_id: string,
    scene_id: string,
    frameNumber: string,
    ordering: number,
  ) {
    return this.http.post(endpointAdd10FramesToStoryBoard, {
      project_id,
      scene_id,
      frameNumber,
      ordering
    }).pipe(
      tap(res => res)
    );
  }

  addFrameToScene(
    project_id: string,
    scene_id: string,
    frameNumber: string,
    ordering: number,
  ) {
    return this.http.post(endpointAddFrameToStoryBoard, {
      project_id,
      scene_id,
      frameNumber,
      ordering
    }).pipe(
      tap(res => res)
    );
  }

  addFrameFromLibraryToScene(
    libraryItem,
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    versionId: string,
    sceneId: string,
    frameNumber: string,
    ordering: number
  ) {

    let url = endpointAddFrameFromLibarary.replace('--projectAssetId--', projectAssetId);
    url = url.replace('--projectTabId--', projectAssetTabId);
    url = url.replace('--versionId--', versionId);
    url = url.replace('--projectId--', projectId);
    return this.http.post(url, {
      sceneId,
      frameNumber,
      ordering,
      libraryItem: libraryItem.id
    }).pipe(
      tap(res => res)
    );
  }

  deleteFrame(frameId: any) {
    return this.http.delete(endpointDeleteFrame.replace('--frameId--', frameId)).pipe(
      tap(res => res)
    );
  }

  getLatestFramesForFilesTab(
    projectAssetId: string,
    projectTabId: string
  ) {
    let url = endpointGetFramesOfLastVersion.replace('--projectAssetId--', projectAssetId);
    url = url.replace('--projectTabId--', projectTabId);
    return this.http.get(url).pipe(
      tap(res => res)
    );
  }

  updateFrameOrderInOneScene(
    projectID,
    projectAssetId,
    projectAsseTabId,
    projectAssetTabVersionId,
    sceneId,
    frameOrderd
  ) {

    const url = endpointUpdateFrameOrderInOneScene.replace('--projectId--', projectID);
    const url2 = url.replace('--projectAssetId--', projectAssetId);
    const url3 = url2.replace('--projectAsseTabId--', projectAsseTabId);
    const url4 = url3.replace('--projectAssetTabVersionId--', projectAssetTabVersionId);

    let data: frameOrdering[] = [];

    frameOrderd.forEach((item, index) => {
      if (item) {
        data.push(
          {
            id: item?.id,
            ordering: (index + 1) * 10,
            frameNumber: item?.frameNumber
          }
        );
      }
    });


    return this.http.post(url4, {
      scene: sceneId,
      frames: data
    }).pipe(
      tap(res => res)
    );
  }

  updateFrameOrderInAllScenes(
    projectID,
    projectAssetId,
    projectAsseTabId,
    projectAssetTabVersionId,
    frames: any,
    previousSceneId,
    newSceneId,
    changedFrame
  ) {

    const url = endpointUpdateFrameOrderInAllScenes.replace('--projectId--', projectID);
    const url2 = url.replace('--projectAssetId--', projectAssetId);
    const url3 = url2.replace('--projectAssetTabId--', projectAsseTabId);
    const url4 = url3.replace('--projectAssetTabVersionId--', projectAssetTabVersionId);

    let data: frameOrdering[] = [];
    let sceneData: [] = [];
    frames.forEach((frame, index) => {
      frame.index = index;
      if (changedFrame.id === frame.id) {
        frame.previousSceneId = previousSceneId;
        frame.newSceneId = newSceneId;
      }
      frame.ordering = index;
      frame.scene_id = newSceneId;

    });

    return this.http.post(url4, {frames}).pipe(
      tap(res => res)
    );
  }

  copyFrameOnServer(
    sceneId,
    currentFrame,
    newOrdering,
    newFrameNumber
  ) {
    const url = endpointCopyExistingFrameInStoryBoard.replace('--frameId--', currentFrame.id)
    return this.http.post(url, {
      currentFrame,
      ordering: newOrdering,
      frameNumber: newFrameNumber
    })
      .pipe(
        tap(res => res)
      );

  }

  getNextPrevFrame(frameId: string, direction:string) {
    const url = endpointNextFrameInStoryBoard.replace('--frameId--', frameId);
    return this.http.get(url.replace('--direction--', direction)).pipe(
      tap(res => res)
    );
  }

}
