import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from "../../shared/services/layout.service";
import {TabTemplateService} from "../../shared/services/tab-template.service";
import {TabTemplate} from "../../shared/models/tabTemplate";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.sass']
})
export class TemplatesComponent implements OnInit, OnDestroy {

  public loading = true;
  public templates: TabTemplate[] = [];
  private ngUnsubscribe = new Subject();

  constructor(
    private layoutService: LayoutService,
    private templateService: TabTemplateService,
    private toastrService: ToastrService
  ) {
    this.layoutService.setTitle('Templates');
    this.layoutService.setContainerFluid(false);
    this.layoutService.setShowBigTitleInHeader(false);
  }

  ngOnInit(): void {
    this.templateService.getTabTemplateListAdmin().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (templates) => {
          this.templates = templates;
          this.loading = false;
      },
      (error) => {
        this.toastrService.error('Could not load templates');
      }
    );
  }

  getTemplatesByType(type: string) {
    return this.templates.filter((template) => {
      return template.type === type;
    });
  }

  changeTemplateActiveStatus(template) {
    this.templateService.changeTemplateActiveStatus(template).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (response) => {
        this.toastrService.success('Template active state changed');
        template.active = !template.active;
      },
      (error) => {
        this.toastrService.error('Error while changing template active state');
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
