<div class="ng-autocomplete">
  <ng-autocomplete
    [data]="data"
    [searchKeyword]="keyword"
    placeholder="{{placeHolder}}"
    (selected)='selectEvent($event)'
    (inputChanged)='onChangeSearch($event)'
    (inputFocused)='onFocused($event)'
    (inputCleared)="onChangeSearch($event)"
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [initialValue]="initialValue"
    [disabled]="disabled"
    class="type-ahead"
  >
  </ng-autocomplete>
<!-- there is also the option for historicalData in order to save it to the form! -->
  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.name"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>
