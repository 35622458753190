import {Component, HostListener, OnInit} from '@angular/core';
import {LibraryService} from "../../../services/library.service";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {NestedTreeControl} from "@angular/cdk/tree";
import {LayoutService} from "../../../services/layout.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-library-admin-list',
  templateUrl: './library-admin-list.component.html',
  styleUrls: ['./library-admin-list.component.sass']
})
export class LibraryAdminListComponent implements OnInit {

  public libraries: any;

  private ngUnsubscribe = new Subject();

  public treeControl = new NestedTreeControl<any>(node => node.children);

  public dataSource = new MatTreeNestedDataSource<any>();

  public hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  public uploadRunning = false;

  public uploadFile: File | null = null;

  public detail;

  public detailInformationFormGroup = new FormGroup({
    description: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    fastlib: new FormControl('')
  });

  public selectedCategory;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (typeof event !== "undefined" && event.item && typeof event.item(0) !== "undefined") {
      this.uploadFile = event && event.item(0);

    }
  }

  public formData = new FormGroup({
    libraryCategoryId: new FormControl(null, Validators.required),
    data: new FormControl(null, Validators.required),
    fileName: new FormControl(null, Validators.required),
    description: new FormControl(null),
    fastlib: new FormControl(null)
  });


  constructor(
    private libraryService: LibraryService,
    private toastrService: ToastrService,
    private layoutService: LayoutService
  ) {
  }

  ngOnInit(): void {
    this.layoutService.setTitle('Library Admin List');
    this.libraryService.getLibraryAdminViewList().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (libraries: any) => {
        this.libraries = libraries;
        this.dataSource.data = libraries;
      },
      (error) => {
        this.toastrService.error('Could not load library categories');
      }
    );
  }

  filterResults() {

  }

  uploadFileToLibrary() {

    this.uploadRunning = this.libraryService.uploadRunning;

    const fileData = this.uploadFile;
    const libraryCategoryId = this.formData.get('libraryCategoryId')?.value;
    const fileName = this.formData.get('fileName')?.value;
    const description = this.formData.get('description')?.value;
    const fastlib = this.formData.get('fastlib')?.value;

    const result = this.libraryService.uploadFile(
      libraryCategoryId,
      fileData,
      fileName,
      description,
      fastlib
    );
  }


  loadDetailOfItem(item, category) {
    this.detail = item;
    this.selectedCategory = category;

    this.detailInformationFormGroup.get('name')?.patchValue(item.name);
    this.detailInformationFormGroup.get('description')?.patchValue(item.description);
    this.detailInformationFormGroup.get('fastlib')?.patchValue(item.fastLib);

  }

  deleteItem(item) {

  }

  submitLibraryChanges(item) {
    const name = this.detailInformationFormGroup.get('name')?.value;
    const description = this.detailInformationFormGroup.get('description')?.value;
    const fastlib = this.detailInformationFormGroup.get('fastlib')?.value;

    this.libraryService.updateItem(
      item,
      description,
      name,
      fastlib
    ).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Changes saved');
        window.location.reload();
      },
      (error) => {
        this.toastrService.error('Could not save changes');
      }
    )
  }


}
