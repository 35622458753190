import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {User} from "../models/user";
import {Observable, Subject, throwError} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {Ability, AbilityBuilder} from "@casl/ability";
import {ToastrService} from "ngx-toastr";

const endpointRegister = environment.apiUrl + 'users/register';
const endpointLogin = environment.apiUrl + 'login';
const endpointLogout = environment.apiUrl + 'logout';
const endpointProfile = environment.apiUrl + 'users/userProfile';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser!: User;
  public loginSuccess = false;
  public userLoginIsLoading = false;
  public userLoginFailed = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    public router: Router,
    private ability: Ability,
    private toastrService: ToastrService
  ) {
  }

  register(user: User): Observable<any> {
    return this.http.post(endpointRegister, user).pipe(
      catchError(this.handleError)
    )
  }

  updateUserProfile() {
    return this.http.get(endpointProfile).subscribe(
      (res: any) => {
        this.currentUser = res;
        this.userLoginFailed = false;
        this.userLoginIsLoading = false;
        this.updateAbilities(res.abilities[0]);
        localStorage.setItem('access_token', res.token);
      },
      (error) => {
        this.handleError(error);
      }
    );

  }

  login(user) {
    this.userLoginIsLoading = true;
    return this.http.post(endpointLogin, user)
      .subscribe((res: any) => {
          localStorage.setItem('access_token', res.token)
          this.loginSuccess = true;
          this.currentUser = res;
          this.userLoginFailed = false;
          this.userLoginIsLoading = false;
          this.updateAbilities(res.abilities[0]);
        },
        (error) => {
          this.userLoginFailed = true;
          this.userLoginIsLoading = false;
        })
  }

  getUserProfile(userId: string): Observable<any> {
    return this.http.get(endpointProfile).pipe(
      map((res) => {
        return res || {}
      }),
      catchError(this.handleError)
    )
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null);
  }

  doLogout() {
    this.http.get(endpointLogout).pipe(
      tap(res => res)
    ).subscribe(
      (success) => {
        localStorage.clear();
        this.ability.update([]);
        window.location.href = '/';
      },
      (error) => {
        this.toastrService.error('Could not logout');
      }
    );

  }

  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }

  checkIfUserHasAlreadyCredentialsUpdateAbilities() {
    if (localStorage.getItem('access_token') !== null) {
      this.updateUserProfile();
    }
  }

  private updateAbilities(abilities: any) {
    const {can, rules} = new AbilityBuilder(Ability);

    if (abilities && Array.isArray(abilities)) {

      abilities.forEach(function (item: any) {
        const ability = item.name.split('_');
        can(ability[1], ability[0]);
      });
    }

    this.ability.update(rules);
  }

  onDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
