import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ProjectAddComponent} from "../../../../../components/projects/project-add/project-add.component";
import {SearchService} from "../../../../services/search.service";
import {Observable, Observer, of, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {LayoutService} from '../../../../services/layout.service';

interface ProjectList {
  id: string;
  name: string;
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  modalRef?: BsModalRef;
  public searchTermUpdate = new Subject<string>();
  public searchTerm: string = '';
  public ngUnsubsribe = new Subject();
  public searchResults;
  public projectName;

  constructor(
    private modalService: BsModalService,
    private searchService: SearchService,
    private toastrService: ToastrService,
    private layoutService: LayoutService
  ) {

  }

  ngOnInit(): void {
    this.searchTermUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(
      (value) => {
        this.searchService.search(value).pipe(
          takeUntil(this.ngUnsubsribe)
        ).subscribe(
          (searchResults) => {
            this.searchResults = searchResults;
          },
          (error) => {
            this.toastrService.error('Could not retrive Searchresults');
          }
        )
      }
    );

    this.layoutService.titleInHeader.subscribe(
      (success) => { this.projectName = success;},
      (error) => {}
    );

  }

  openModalProjectAdd() {
    const modalConfig: ModalOptions = {
      initialState: {
      },
      class: 'modal-green modal-lg'
    };
    this.modalRef = this.modalService.show(
      ProjectAddComponent,
      modalConfig
    );
  }

  ngOnDestroy() {
    this.ngUnsubsribe.next();
    this.ngUnsubsribe.complete();
  }

}
