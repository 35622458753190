import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";

const endpointSearch = environment.apiUrl + 'searches/search';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient
  ) { }

  search(searchTerm: string) {
    return this.http.post(endpointSearch, {searchTerm}).pipe(
      tap( res => res)
    );
  }
}
