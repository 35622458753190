<div class="row" *ngIf="inline">
  <div class="offset-11 col-lg-1">
    <app-icon-close-rounded class="d-inline-block" (click)="closePopOver()"></app-icon-close-rounded>
  </div>
</div>
<div class="row">
<div class="col-lg-12 mt-3">
  <div class="form-check">
    <input name="private" id="private" type="checkbox" class="form-check-input"  [(ngModel)]="internal"/>
    <label class="form-check-label" for="private">Private</label>
  </div>
</div>
<div class="col-lg-12 mt-2">
  <textarea
    placeholder="Add a comment here..."
    [(ngModel)]="content"
  ></textarea>
</div>
<div class="col-lg-12">
  <button class="comment-button" (click)="addComment()">comment</button>
</div>
</div>
