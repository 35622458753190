import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroupDirective, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {CommentService} from "../../../services/comment.service";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../../services/auth.service";
import {CommentsRepository} from "../../../stores/comments/comments.repository";
import {takeUntil} from "rxjs/operators";
import {User} from '../../../models/user';

@Component({
  selector: 'app-comments-video-add',
  templateUrl: './comments-video-add.component.html',
  styleUrls: ['./comments-video-add.component.sass']
})
export class CommentsVideoAddComponent implements OnInit {

  public currentDate = new Date();
  public content: string | undefined;
  public commentForm = this.fb.group({
    content: ['', Validators.required],
    internal: [0, Validators.required]
  });

  @Input()  projectId!: string;
  @Input()  projectAssetTabVersionId!: string;
  @Input()  tabId!: string;
  @Input()  projectAssetId!: string;
  @Input()  projectAssetTabId!: string;
  @Input()  showAddComment: boolean = true;
  @Input()  frameNumber!: string;
  @Input()  currentTime;
  @Input()  versionId!: string;
  @Input()  videoId!:string;



  private ngUnsubscribe = new Subject();
  private userId!: string;

  public showLoadingSpinner: boolean = false;

  public user;

  constructor(
    private commentService: CommentService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private authService: AuthService,
    public commentsRepository: CommentsRepository
  ) {

  }

  ngOnInit(): void {
    this.commentsRepository.comments$.subscribe((state) => {
    });
    this.user = this.authService.currentUser;
  }

  saveComment(formData: any, formDirective: FormGroupDirective) {

    const content = formData.value.content;
    const internal = formData.value.internal;

    if(this.authService.currentUser.id && this.commentForm.valid) {
      this.userId = this.authService.currentUser.id;

      // this.commentService.postingComment.next(true);

      this.showLoadingSpinner = true;
      // this.commentService.postCommentForStoryBoard(
      //   this.projectId,
      //   this.projectAssetId,
      //   this.projectAssetTabId,
      //   this.projectAssetTabVersionId,
      //   this.sceneId,
      //   this.frameId,
      //   this.userId,
      //   content,
      //   internal,
      //   this.frameNumber
      // );
    }

    this.commentService.postingComment.subscribe(
      (success) => {
        this.showLoadingSpinner = false;
        this.toastrService.success('Comment has been saved');
        this.commentForm.controls['content'].setValue('');
        this.commentForm.controls['content'].updateValueAndValidity();
        this.commentForm.clearValidators();
      },
      (error) => {
        this.toastrService.error('Comment could not be saved');
      }
    );


  }

  addNewComment() {
    this.commentService.postCommentVideo(
      this.projectId,
      this.versionId,
      this.commentForm.controls['content'].value,
      false,
      this.projectAssetId,
      this.projectAssetTabId,
      this.videoId
    ).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(response =>{
        this.commentForm.controls['content'].setValue('');
        this.commentForm.controls['content'].updateValueAndValidity();
        this.commentForm.clearValidators();
        this.commentsRepository.updateCommentsList(response);
      },
      (error) => {
        this.toastrService.error('Could not save Comment');
      }
    );
  }

  checkIfContentLengthHigherThan3Chars() {
    return this.commentForm.get('content')?.value.length > 3;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
