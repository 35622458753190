import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {TabTemplateService} from "../../../shared/services/tab-template.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-add-project-asset-template',
  templateUrl: './add-project-asset-template.component.html',
  styleUrls: ['./add-project-asset-template.component.sass']
})
export class AddProjectAssetTemplateComponent implements OnInit, OnDestroy {

  public templateName: string = '';
  private modalRef?: BsModalRef;
  private ngUnsubscribe = new Subject();
  public projectId;
  public projectAssetId;

  constructor(
    private toastrService: ToastrService,
    private templateService: TabTemplateService
  ) { }

  ngOnInit(): void {
  }

  createTemplateFromProject() {
    this.templateService.addTemplate(
      this.templateName,
      this.projectId,
      this.projectAssetId,
      null,
      null
    ).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Added as Template');
        this.modalRef?.hide();
      },
      (error) => {
        this.toastrService.error('Could not add Template, maybe it has already been added?');
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
