import {Injectable, OnDestroy} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {takeUntil, tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";

const endpointPostNewLibraryCategory = environment.apiUrl + 'libraries/createLibrary'
const endpointGetLibraryCategories = environment.apiUrl + 'libraries/getCategories'
const endpointUploadFileToLibrary = environment.apiUrl + 'libraries/uploadCategoryItem/--libraryCategoryId--';
const endpointUpdateLibraryItem = environment.apiUrl + 'libraries/updateItem/--itemId--';
const endpointGetLibraryItems = environment.apiUrl + 'libraries/getFastLib';

@Injectable({
  providedIn: 'root'
})
export class LibraryService implements OnDestroy {

  private ngUnsubscribe = new Subject();
  public uploadRunning = false;

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
  }

  createNewLibraryCategory(
    parentId?: string
  ) {
    if (parentId) {
      return this.http.post(endpointPostNewLibraryCategory, {parentId}).pipe(
        tap(res => res)
      );
    } else {
      return this.http.post(endpointPostNewLibraryCategory, {}).pipe(
        tap(res => res)
      );
    }
  }

  getLibraryAdminViewList() {
    return this.http.get<any>(endpointGetLibraryCategories).pipe(
      tap(res => res)
    );
  }

  uploadFile(
    libraryCategoryId: string,
    data,
    fileName: string,
    description: string,
    fastlib: boolean
  ) {
    const url = endpointUploadFileToLibrary.replace('--libraryCategoryId--', libraryCategoryId);
    let reader = new FileReader();
    this.uploadRunning = true;
    reader.onload = () => {
      const dataRead = reader.result?.toString();
      return this.http.post<any>(
        url,
        {
          data: dataRead,
          fileName,
          libraryCategoryId,
          description,
          fastlib
        }
      ).pipe(
        tap(res => res),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (success) => {
          this.toastrService.success('File successfully uploaded');
          this.uploadRunning = false;
        },
        (error) => {
          this.toastrService.error('Error while uploading file');
          this.uploadRunning = false;
        }
      );
    }
    return reader.readAsDataURL(data);
  }

  updateItem(item, description, name, fastlib) {
    const url = endpointUpdateLibraryItem.replace('--itemId--', item.id);
    return this.http.post(url, {
      description,
      name,
      fastlib
    })
      .pipe(
        tap(res=>res)
      );
  }

  getFastLibItems() {
    return this.http.get<any>(endpointGetLibraryItems).pipe(
      tap(res=>res)
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
