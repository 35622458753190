import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {

    return value ?? value.replace(/\n/g, '<br/>');
  }

}
