import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {UserService} from "../../../shared/services/user.service";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AuthService} from "../../../shared/services/auth.service";
import {LayoutService} from "../../../shared/services/layout.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.sass']
})
export class UserResetPasswordComponent implements OnInit, OnDestroy {

  public password = new FormControl('', [Validators.required]);
  public newPassword = new FormControl('', [Validators.required]);
  public newPasswordConfirmation = new FormControl('', [Validators.required]);

  private ngUnsubscribe = new Subject();

  public modalRef?: BsModalRef;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private layoutService: LayoutService,
    private modalService: BsModalService
  ) { }


  ngOnInit(): void {
  }

  submitPasswordChange(e: Event) {
    e.preventDefault();
    this.userService.changePassword(this.password.value, this.newPassword.value).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (response) => {
        this.authService.updateUserProfile();
        this.modalService.hide();
        this.toastrService.success('Password has been changed');
      },
      (error) => {
        this.toastrService.error('Password could not be changed');
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
