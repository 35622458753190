import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Project} from "../models/project";

const endpointGetProjectDashboard = environment.apiUrl + 'projects/list';
const endpointGetProject = environment.apiUrl + 'projects/show/--projectId--';
const endpointCreateProject = environment.apiUrl + 'projects/create';
const endpointUpdateProject = environment.apiUrl + 'projects/update/--projectId--';
const endpointGetProjectsPerClient = environment.apiUrl + 'project/getProjectsPerClient/--clientId--';

const endpointGetProjectUsers = environment.apiUrl + 'project/getUsersPerProject/--projectId--';
const endpointGetPendingProjectUser = environment.apiUrl + 'users/getPendingUsers/--projectId--';
const endpointGetActiveProjectUser = environment.apiUrl + 'users/getActiveUsers/--projectId--';
const endpointAddExistingUserToProject = environment.apiUrl + 'users/addExistingUserToProject/--projectId--';

const endpointDeactivateProject = environment.apiUrl + 'projects/updateActiveState/--projectId--';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private http: HttpClient
  ) {
  }

  getProjectList(): Observable<any> {
    return this.http.get(endpointGetProjectDashboard).pipe(
      tap(res => {
        return res;
      })
    );
  }

  getProject(projectId: string): Observable<any> {
    return this.http.get(endpointGetProject.replace('--projectId--', projectId)).pipe(
      tap(res => {
        return res;
      })
    );
  }

  createProject(project: Project) {
    return this.http.post<any>(endpointCreateProject, {...project}).pipe(
      tap(res => res)
    );
  }

  updateProject(project: Project, projectId: string) {
    const url = endpointUpdateProject.replace('--projectId--', projectId);
    return this.http.post<any>(url, {...project}).pipe(
      tap(res => res)
    );
  }

  getProjectListPerClient(clientId: string) {
    const url = endpointGetProjectsPerClient.replace('--clientId--', clientId);
    return this.http.get(endpointGetProjectDashboard).pipe(
      tap(res => {
        return res;
      })
    );
  }

  getUserListPerProject(projectId: string) {

    const url = endpointGetProjectUsers.replace('--projectId--', projectId);
    return this.http.get(url).pipe(
      tap(res => res)
    );
  }

  getPendingProjectUser(projectId: string) {
    const url = endpointGetPendingProjectUser.replace('--projectId--', projectId);
    return this.http.get(url).pipe(
      tap(res => res)
    );
  }

  getActiveUsers(projectId) {
    const url = endpointGetActiveProjectUser.replace('--projectId--', projectId);
    return this.http.get(url).pipe(
      tap(res => res)
    );
  }

  addExistingUser(user, projectId) {
    const url = endpointAddExistingUserToProject.replace('--projectId--', projectId);
    return this.http.post<any>(url, {userId: user.id}).pipe(
      tap(res => res)
    );
  }

  updateActiveStatusOfProject(projectId: string, state: boolean) {
    const url = endpointDeactivateProject.replace('--projectId--', projectId);
    return this.http.post(url, {active: state}).pipe(
      tap(res => res)
    );
  }

}
