<app-modal-green-header-large [modalTitle]="'Add Project'"></app-modal-green-header-large>
<div class="modal-body pt-0">
  <hr>
  <form [formGroup]="projectForm">
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">Title *</label>
        <input type="text"
               formControlName="name"
               class="form-control vvv-input-big"
        >
        <small class="ml-3 form-text text-white">
          Ein Überbegriff für das Projekt
        </small>
      </div>
    </div>
    <ng-container formGroupName="config">
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label class="modal-form-label">Template</label>
          <select formControlName="projectTemplate"
                  class="form-control vvv-select-big"
          >
            <option *ngFor="let projectTemplate of projectTemplates"
                    [value]="projectTemplate.id">{{projectTemplate.name}}</option>
          </select>
          <small class="form-text ">
          </small>
        </div>
      </div>
    </ng-container>
    <div class="form-row">
<!--      <div class="form-group col-lg-4">-->
<!--        <ng-container formGroupName="config">-->
<!--          <label class="modal-form-label">Product *</label>-->
<!--          <select formControlName="product_id"-->
<!--                  class="form-control vvv-select-big"-->
<!--          >-->
<!--            <option *ngFor="let product of products"-->
<!--                    [value]="product.id">{{product.name}}</option>-->
<!--          </select>-->
<!--          <small class="form-text ">-->
<!--          </small>-->
<!--        </ng-container>-->
<!--      </div>-->
      <div class="form-group col-lg-4">
        <label class="modal-form-label">Budget Total</label>
        <input formControlName="budget"
               type="text"
               class="form-control vvv-input-big"
               mask="0*,00"
        >
      </div>
      <div class="form-group col-lg-4">
        <label class="modal-form-label">Deadline *</label>
        <input formControlName="deliveryDate"
               type="text" class="form-control vvv-input-big" bsDatepicker
        >
        <small class="form-text ">
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-4">
        <label class="modal-form-label">PM </label>
        <app-input-type-ahead
          [data]="filterByUserGroup(userGroup.IS_PROJECT_MANAGER)"
          [searchKeyWord]="'user'"
          [placeHolder]="'Select PM'"
          (selectedEvent)="onSelectPM($event)"
          *ngIf="!loadingSellerAndPMs"
        >
        </app-input-type-ahead>
<!--        <select formControlName="pm_id"-->
<!--                class="form-control vvv-select-big"-->
<!--        >-->
<!--          <option *ngFor="let user of filterByUserGroup(userGroup.IS_PROJECT_MANAGER)"-->
<!--                  [value]="user.id">{{user.name}}</option>-->
<!--        </select>-->
        <small class="form-text ">
        </small>
      </div>
      <div class="form-group col-lg-4">
        <label class="modal-form-label">Client </label>
        <app-input-type-ahead
          [data]="clients"
          [searchKeyWord]="'client'"
          [placeHolder]="'Select Client'"
          (selectedEvent)="onSelectClient($event)"
          *ngIf="!loadingClients"
        >
        </app-input-type-ahead>
<!--        <select formControlName="client_id"-->
<!--                class="form-control vvv-select-big"-->
<!--        >-->
<!--          <option *ngFor="let client of clients"-->
<!--                  [value]="client.id">{{client.name}}</option>-->
<!--        </select>-->
        <small class="form-text ">
        </small>
      </div>
    </div>
    <ng-container formGroupName="config">
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label class="modal-form-label">Description</label>
          <textarea formControlName="description"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
          ></textarea>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label class="modal-form-label">Hive Link</label>
          <input formControlName="linkId2"
                 type="url"
                 class="form-control vvv-input-big"
          >
          <small class="ml-3 form-text text-white">

          </small>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label class="modal-form-label">Moco Link</label>
          <input formControlName="linkId1"
                 type="url"
                 class="form-control vvv-input-big"
          >
          <small class="ml-3 form-text text-white">

          </small>
        </div>
      </div>
    </ng-container>

    <ng-container formGroupName="config">

    </ng-container>
    <div class="form-row">
      <div class="col-lg-12">
        <hr class="pb-3">
        <button type="submit"
                (click)="submitProject()"
                class="vvv-input-medium btn btn-primary background-white text-vvv-green-main text-bold">
          Add
        </button>
      </div>
    </div>
  </form>
</div>
