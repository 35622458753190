import {Injectable} from '@angular/core';
import {tap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ProjectAssetTab} from "../models/projectAssetTab";
import {head} from "@ngneat/elf";
import {FormGroup} from "@angular/forms";

const endpointPostAddNewProjectAssetTab = environment.apiUrl + 'projectAsset/addTabToAsset/--projectId--/--assetId--';
const endpointEditProjectAssetTab = environment.apiUrl + 'projectAsset/editTab/--projectId--/--assetId--/--projectAssetTabId--/--versionId--';
const endpointEditStoryBoardConfiguration = environment.apiUrl + 'projectAsset/updateStoryboardConfiguration/--projectId--/--projectAssetId--/--projectAssetTabId--/--projectAssetTabVersionId--';
const endpointGetProjectAssetTabs = environment.apiUrl + 'projectAsset/getAssetTabs/--projectId--/--assetId--';
const endpointGetProjectAssetTabConfig = environment.apiUrl + 'projectAsset/getAssetTab/--projectId--/--projectAssetId--' +
  '/--tabId--/--versionId--';
const endpointUpdateProjectAssetTabOrdering = environment.apiUrl + 'projectAsset/updateOrderProjectAssetTabs/--projectId--/--projectAssetId--';
const endpointUpdateProjectAssetPublicStatus = environment.apiUrl + 'projectAsset/updateProjectAssetTabVersionPublicStatus/--projectId--/--versionId--';
const endpointCopyProjectAssetTabToThisTab = environment.apiUrl + 'projectAsset/copyProjectAssetTabToThisTab/--projectAssetId--/--versionId--';
const endpointDisableProjectAssetTab = environment.apiUrl + 'projectAsset/disableProjectAssetTab/--projectAssetTabId--';

@Injectable({
  providedIn: 'root'
})
export class TabService {

  constructor(
    private http: HttpClient
  ) {}

  addTabToAsset(
    projectId: string,
    assetId: string,
    tab: any,
    file: File | null
  ) {

    const url = endpointPostAddNewProjectAssetTab.replace('--projectId--', projectId);
    const finalUrl = url.replace('--assetId--', assetId);
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<any>(finalUrl, tab, {headers}).pipe(
      tap(res => res)
    );
  }

  editStoryBoardAsset(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: any,
    projectAssetTabVersionId: string,
    storyBoardConfiguration
  ) {
    const url = endpointEditStoryBoardConfiguration.replace('--projectId--', projectId);
    const url2 = url.replace('--projectAssetId--', projectAssetId);
    const url4 = url2.replace('--projectAssetTabId--', projectAssetTabId);
    const url5 = url4.replace('--projectAssetTabVersionId--', projectAssetTabVersionId);
    return this.http.post<any>(url5, storyBoardConfiguration).pipe(
      tap(res => res)
    );
  }

  getProjectAssetTabs(
    projectId: string,
    projectAssetId: string
  ) {

    const url = endpointGetProjectAssetTabs.replace('--projectId--', projectId);
    const queryUrl = url.replace('--assetId--', projectAssetId);

    return this.http.get<ProjectAssetTab[]>(queryUrl).pipe(
      tap(res => res)
    );

  }

  getProjectAssetTabConfig(
    projectId: string,
    projectAssetId: string,
    tabId: string,
    versionId: string
  ) {
    let url = endpointGetProjectAssetTabConfig.replace('--projectId--', projectId);
    url = url.replace('--projectAssetId--', projectAssetId);
    url = url.replace('--tabId--', tabId);
    url = url.replace('--versionId--', versionId);

    return this.http.get<any>(url).pipe(
      tap(res => res)
    );
  }

  updateOrderingProjectAssetTabs(
    projectId: string,
    projectAssetId: string,
    projectAssetTabs
  ) {
    let url = endpointUpdateProjectAssetTabOrdering.replace('--projectId--', projectId);
    url = url.replace('--projectAssetId--', projectAssetId);

    return this.http.post<any>(url, {projectAssetTabs}).pipe(
      tap(res => res)
    );

  }

  copyOtherProjectAssetTabToCurrentProjectAssetTab(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    projectAssetTabVersionId: string,
    templateId?: string,
    clientId?: string,
    otherProjectId?: string,
    otherProjectAssetId?: string,
    otherProjectAssetTabId?: string,
    otherProjectAssetTabVersionId?: string
  ) {
    let url = endpointCopyProjectAssetTabToThisTab.replace('--projectAssetId--', projectAssetId);
    url = url.replace('--versionId--', projectAssetTabVersionId);

    return this.http.post<any>(url, {
      projectId,
      projectAssetId,
      projectAssetTabId,
      projectAssetTabVersionId,
      templateId,
      clientId,
      otherProjectId,
      otherProjectAssetId,
      otherProjectAssetTabId,
      otherProjectAssetTabVersionId
    }).pipe(
      tap(res => res)
    );
  }

  disableProjectAssetTab(projectAssetTabId: string) {
    let url = endpointDisableProjectAssetTab.replace('--projectAssetTabId--', projectAssetTabId);
    return this.http.post(
      url,
      {}
    ).pipe(
      tap(res=>res)
    );
  }



}
