<div class="container mt-5">
  <div class="row mb-5">
    <div class="col-lg-12">
      <img src="assets/img/vidoBoard-logo.svg"
           alt="vidoBoard by {{companyName}}"
           title="vidoBoard by {{companyName}}"
           class="login-logo img-fluid" />
    </div>
  </div>
  <div class="row login-mt-welcome">
    <div class="col-lg-6">
      <h2 class="login-title">
        Welcome to {{title}} <br/>
        by {{companyName}}
      </h2>
      <p class="mt-4 mb-4">
        <strong>Please log in with your email and password</strong>
      </p>
      <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
        <div class="mb-3">
          <input
            type="email"
            class="form-control vvv-input-big"
            id="userEmail"
            name="email"
            placeholder="E-Mail Address"
            formControlName="email"
          >
          <div *ngIf="
          (
          loginForm.controls['email'].invalid &&
          loginForm.controls['email'].dirty ||
          loginForm.controls['email'].touched
          ) &&
          submitButtonTouched
          " class="vvv-input-big-error">
            <span *ngIf="loginForm.controls['email'].errors?.required">
              Please enter email address
            </span>
            <span *ngIf="loginForm.controls['email'].errors?.email">
              Please enter valid email address
            </span>
          </div>
        </div>
        <div class="mb-3">
          <input
            type="password"
            id="userPassword"
            name="password"
            class="form-control vvv-input-big"
            placeholder="Password"
            formControlName="password">
          <div *ngIf="
                        (
                        loginForm.controls['password'].invalid &&
                        loginForm.controls['password'].dirty ||
                        loginForm.controls['password'].touched
                        ) &&
                        loginForm.controls['password'].errors?.required && submitButtonTouched"
               class="vvv-input-big-error">
            Please enter password
          </div>
        </div>
        <p class="mb-4">
          Questions, lost Password etc. ? <br/>
          Contact <a href="mailto:contact@vidoboard.com">contact@vidoboard.com</a> or call +43 1 30 50 711
        </p>
        <p *ngIf="getLoginError()" class="vvv-input-big-error">
          We do not know the combination of username and password.
        </p>
        <button type="submit" class="
          btn
          btn-primary
          rounded-pill
          pr-4 pl-4"
                (click)="clickSubmitButton()"
        >Login
        </button>
        <br />
        <small><i>(Release: 0.20230920.6)</i></small>
        <ng-container *ngIf="waitingForLoginResponse">
          <app-loading-dots class="d-inline-block ml-5"></app-loading-dots>
        </ng-container>
        <ng-container *ngIf="loginSuccess">
          <app-loading-success class="ml-5"></app-loading-success>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-6">
      <img src="assets/img/201109_VVV_Header_erklarem-img.svg" alt="{{companyName}} Welcome" title="{{companyName}} Welcome" class="img-fluid login-welcome-asset" />
    </div>
  </div>
  <div class="row mt-5 align-items-end">
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 text-center">
          <h3 class="">quick {{title}}</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="row gx-5 mt-5 justify-content-center">
    <!-- TODO: put this into its own card component -->
    <div class="col-lg-3 mr-2 rounded bg-light-green text-white text-center">
      #asset
    </div>
    <div class="col-lg-3 mr-2 rounded bg-light-green text-white text-center">
      #asset
    </div>
    <div class="col-lg-3 rounded bg-light-green text-white text-center">
      #asset
    </div>
  </div>
</div>

<router-outlet></router-outlet>
