import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./shared/components/user/login/login.component";
import {AlwaysAuthGuard} from "./shared/guards/always-auth.guard";
import {LayoutComponent} from "./shared/components/common/layout/layout.component";
import {DemoComponent} from "./shared/components/common/demo/demo.component";
import {LogoutComponent} from "./shared/components/user/logout/logout.component";
import {LibraryAdminListComponent} from "./shared/components/library/library-admin-list/library-admin-list.component";
import {WelcomeComponent} from "./shared/components/user/welcome/welcome.component";
import {TemplatesComponent} from "./components/templates/templates.component";

const routes: Routes = [
  {
    'path': 'login',
    component: LoginComponent
  },
  {
    'path': 'welcome/:activationToken/:projectId',
    component: WelcomeComponent
  },
  {
    'path': 'logout',
    component: LogoutComponent
  },
  {
    'path': 'demo',
    component: DemoComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AlwaysAuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'projects',
        loadChildren: () => import('./components/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'library-admin',
        component: LibraryAdminListComponent
      },
      {
        path: 'templates',
        component: TemplatesComponent
      }
      // {
      //   path: 'collections',
      //   loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule)
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
