import {User} from "./user";

export class Comment {
  id!: string;
  project_id!: string;
  projectAsset_id!: string;
  projectVersion_id?: string;
  comment_tab_id!: string;
  scene_id?: string | undefined;
  frame_id?: string | undefined;
  user_id!: string;
  content!: string;
  internal: boolean = false;
  commentable_id!: string;
  user!: User;
  created_at!: string;
  frameNumber?: string;
  frame_number?: string;
  config?: string;
  markerPosX?: any;
  markerPosY?: any;
  markerPosT?: any;
  markerPosYOrg?: any;
  markerPosXOrg?: any;
  markerPosRefWidth?: any;
  markerPosRefHeight?: any;
  done?: boolean;
  new?: boolean;
}
