import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";
import {LayoutService} from "../../../shared/services/layout.service";
import {User} from "../../../shared/models/user";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserResetPasswordComponent} from "../user-reset-password/user-reset-password.component";

@Component({
  selector: 'app-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {

  public user:User | undefined;
  public modalRef?: BsModalRef;

  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.layoutService.bigTitleInHeader = false;
    this.layoutService.setTitle('My Profile');
    this.user = this.authService.currentUser;
  }

  doUserLogout() {
    this.authService.doLogout();
  }

  resetPasswordModal() {
    this.modalRef = this.modalService.show(
      UserResetPasswordComponent,
      Object.assign({}, {class: 'modal-lg modal-green'})
    );
  }

}
