import {EventEmitter, Injectable, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {takeUntil, tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Comment} from "../models/comment";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {List} from 'immutable';
import {CommentsRepository} from "../stores/comments/comments.repository";
// import {CommentState, Store} from "../stores/comments/comment.store";


const endpointGetCommentForTab = environment.apiUrl + 'comments/getForTab/--tabId--';
const endpointPostCommentsForStoryBoard = environment.apiUrl + 'comments/createStoryBoardComment/--tabId--';
const endpointGetCommentsForStoryBoard = environment.apiUrl + 'comments/getCommentsForStoryBoard';
const endpointGetCommentsForStoryBoardModal = environment.apiUrl + 'comments/getCommentsForStoryBoardFrame/--frameId--';
const endpointDeleteCommentFromStoryBoardFrame = environment.apiUrl + 'comments/deleteComment/--commentId--';
const endpointPostCommentForStoryBoardModal = environment.apiUrl + 'comments/addStoryBoardModalViewComment/--frameId--';
const endpointPostCommentForVideo = environment.apiUrl + 'comments/addVideoComment/--tabId--';
const endpointPostCommentForVideoWithPos = environment.apiUrl + 'comments/addVideoComment/--tabId--';
const endpointGetCommentsForVideo = environment.apiUrl + 'comments/getCommentsForVideo';

const endpointPostCommentPositionUpdate = environment.apiUrl + 'comments/updateCommentPosition/--commentId--';

const endpointMarkEndpointAsDone = environment.apiUrl + 'comments/updateDoneStatus/--commentId--';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  @Output() commentPostedSuccessfully = new EventEmitter();
  @Output() commentPostedError = new EventEmitter();
  public currentComments: Comment[] = [];
  public loadingComments = true;
  public postingComment = new Subject();


  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    public commentsRepository: CommentsRepository
  ) {

  }

  getCommentsForTab(tabId: string) {
    return this.http.get<Comment[]>(endpointGetCommentForTab.replace('--tabId--', tabId)).pipe(
      tap(res => res)
    ).subscribe(
      (response) => {
        this.currentComments = response;
        this.loadingComments = false;
      },
      (error) => {
        this.toastrService.error('Could not fetch Comments');
      }
    );
  }

  getCommentsForVideo(
    projectId,
    projectAssetId,
    projectAssetTabId,
    versionId
  ) {
    return this.http.post<Comment[]>(endpointGetCommentsForVideo, {
      projectId,
      projectAssetId,
      projectAssetTabId,
      versionId
    }).pipe(
      tap(res => res)
    );
  }

  getCommentsForStoryBoard(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string
  ) {
    return this.http.post<Comment[]>(
      endpointGetCommentsForStoryBoard,
      {projectId, projectAssetId, projectAssetTabId}
    ).pipe(
      tap(res => res)
    )
      .subscribe(
        (commentResponse) => {
          this.currentComments = commentResponse;
          //todo: finialize commentStorage
          // https://tkssharma.com/Creating-Component-level-data-Store-services-using-Observables-in-Angular/
          // this.setState({
          //   ...this.state,
          //   comments: commentResponse,
          //   commentsLoading: false
          // });

          this.commentsRepository.loadComments(commentResponse);
        },
        (errorResponse) => {
          this.toastrService.error('Error while loading Comments');
        }
      );
  }

  postComment(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    projectAssetTabVersionId: string,
    sceneId: string,
    frameId: string,
    userId: string,
    content: string,
    internal: boolean,
    frameNumber: string
  ) {
    // const url = endpointPostCommentForVideo
  }

  postCommentForStoryBoard(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    projectAssetTabVersionId: string,
    sceneId: string,
    frameId: string,
    userId: string,
    content: string,
    internal: boolean,
    frameNumber: string
  ) {
    const realUrl = endpointPostCommentsForStoryBoard.replace('--tabId--', projectAssetTabId);
    const comments = this.http.post<Comment[]>(
      realUrl,
      {
        project_id: projectId,
        user_id: userId,
        comment_tab_id: projectAssetTabId,
        content,
        internal,
        scene_id: sceneId,
        frame_id: frameId,
        frameNumber
      }
    ).pipe(
      tap(res => res)
    ).subscribe(
      (response) => {
        // this.commentsRepository
        this.loadingComments = false;
        this.currentComments = response;
        this.toastrService.success('Comment has been saved');
        this.postingComment.next(false);

        if (response) {
          this.commentsRepository.updateCommentsList(response);
        }
      },
      (error) => {
        this.toastrService.error('Could not fetch Comments');
        this.postingComment.next(false);
      }
    );

    return comments;

  }

  postCommentStoryBoardModalWithPosition(
    projectId: string,
    projectAssetId: string,
    projectAssetTabId: string,
    projectAssetTabVersionId: string,
    sceneId: string,
    frameId: string,
    userId: string,
    content: string,
    internal: boolean,
    frameNumber: string,
    markerPosX: string | number,
    markerPosY: string | number,
    markerPosRefWidth : any,
    markerPosRefHeight: any
  ) {
    return this.http.post<Comment[]>(endpointPostCommentForStoryBoardModal.replace('--frameId--', frameId), {
        projectId,
        projectAssetId,
        projectAssetTabId,
        projectAssetTabVersionId,
        sceneId,
        frameId,
        userId,
        content,
        internal,
        frameNumber,
        markerPosX,
        markerPosY,
        markerPosRefWidth,
        markerPosRefHeight
      }
    )
      .pipe(
        tap(res => res)
      )
      .subscribe(
        (response) => {
          // this.commentsRepository
          this.loadingComments = false;
          let allComments = response;
          allComments.forEach((comment, index) => {
            allComments[index].markerPosXOrg = parseInt(comment.markerPosX);
            allComments[index].markerPosYOrg = parseInt(comment.markerPosY);

          });

          this.currentComments = allComments;
          this.postingComment.next(false);
          this.commentsRepository.updateCommentsList(allComments);
          this.commentPostedSuccessfully.emit(true);
        },
        (error) => {
          this.toastrService.error('Could not fetch Comments');
          this.postingComment.next(false);
          this.commentPostedSuccessfully.emit(true);
        }
      );
  }

  postCommentVideo(
    projectId: string,
    versionId,
    content: string,
    internal,
    projectAssetId,
    projectAssetTabId,
    videoId
  ) {

    return this.http.post<Comment>(endpointPostCommentForVideo.replace('--tabId--', projectAssetTabId),
      {
        projectId,
        content,
        internal,
        projectAssetId,
        projectAssetTabId,
        videoId,
        versionId
      }).pipe(
      tap(res => res)
    );
  }

  postCommentVideoWithPosition(
    projectId: string,
    content: string,
    internal: boolean,
    markerPosX: number,
    markerPosY: number,
    markerPosT,
    projectAssetId,
    projectAssetTabId,
    videoId,
    versionId
  ) {
    return this.http.post<Comment>(endpointPostCommentForVideoWithPos.replace('--tabId--', projectAssetTabId),
      {
        projectId,
        content,
        internal,
        markerPosX,
        markerPosY,
        markerPosT,
        projectAssetId,
        projectAssetTabId,
        videoId,
        versionId
      }).pipe(
      tap(res => res)
    );
  }

  getCurrentComments() {
    return this.currentComments;
  }

  getCommentsForModalInStoryboard(frameId: string) {
    const realEndpoint = endpointGetCommentsForStoryBoardModal.replace('--frameId--', frameId);
    return this.http.get<any>(realEndpoint).pipe(
      tap(res => res)
    );
  }

  deleteComment(
    commentId: string,
    projectId: string,
    projectAssetId: string
  ) {
    const realEndpoint = endpointDeleteCommentFromStoryBoardFrame.replace('--commentId--', commentId);
    return this.http.post(realEndpoint, {
      project_id: projectId,
      comment_id: commentId,
      project_asset_id: projectAssetId
    }).pipe(
      tap(res => res)
    );
  }


  updateStoryBoardCommentPosition(
    commentId,
    markerPosX,
    markerPosY,
    markerPosRefWidth,
    markerPosRefHeight
  )
     {
    const url = endpointPostCommentPositionUpdate.replace('--commentId--', commentId);
    return this.http.post(url, {
      markerPosX,
      markerPosY,
      markerPosRefWidth,
      markerPosRefHeight
    }).pipe(
      tap(res => res)
    );
  }

  updateVideoCommentPosition(commentId, positionX, positionY) {

    const url = endpointPostCommentPositionUpdate.replace('--commentId--', commentId);
    return this.http.post(url, {
      positionX,
      positionY
    }).pipe(
      tap(res => res)
    );
  }

  updateCommentDoneStatus(comment) {
    const url = endpointMarkEndpointAsDone.replace('--commentId--', comment.id);
    return this.http.post(url, {}).pipe(
      tap(res => res)
    );
  }
}
