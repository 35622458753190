import {Component, Input, OnInit} from '@angular/core';
import {LayoutService} from "../../../../../services/layout.service";
import {ProjectAssetTab} from "../../../../../models/projectAssetTab";
import {TabService} from "../../../../../services/tab.service";
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, NavigationEnd, Route, Router, RouterEvent} from "@angular/router";
import {ProjectAssetTabsRepository} from "../../../../../stores/projectAssetTabs/projectAssetTabs.repository";
import {AuthService} from "../../../../../services/auth.service";
import {NavigationService} from "../../../../../services/navigation.service";
import {take, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.sass']
})
export class HeaderTitleComponent implements OnInit {


  @Input() title: string | undefined;
  @Input() containerFluid: boolean = true;
  @Input() projectAssetTabId: string | undefined;
  @Input() projectId: string | null = null;
  @Input() projectAssetId: string = '';
  @Input() tabId: string = '';
  public projectAssetTabs: any;
  private ngUnsubscribe = new Subject();

  public loading = true;

  public currentTitle = '';
  public showTabs = false;
  public showBigTitle = false;

  public isInProjectAsset = false;
  public isInProject = false;
  public isInProjectAssetTab = false;

  public showBackButton = false;

  public navigationProjectId;
  public navigationProjectAssetId;
  public navigationProjectAssetTabId;

  public activeProjectAssetTabId;

  constructor(
    private layoutService: LayoutService,
    private tabService: TabService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private projectAssetTabsRepository: ProjectAssetTabsRepository,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit(): void {

    this.projectAssetTabsRepository.projectAssetTabs$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (projectAssetTabs: any) => {
        this.projectAssetTabs = projectAssetTabs;
        this.loading = false;

      });

    this.layoutService.titleInHeader.pipe(
      takeUntil(this.ngUnsubscribe)
    ).
    subscribe((value) => {
      this.currentTitle = value;
    });

    this.layoutService.showTabsInHeader.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value) => {
      this.showTabs = value;
    });

    this.layoutService.showBigTitleInHeader.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value) => {
      // Todo: refresh this, but its a dirty hack in order to avoid a massive rework in the whole application
      this.showBigTitle = false;
      // this.showBigTitle = value;
    });

    this.layoutService.activeProjectAssetTabId.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value) => {
      this.activeProjectAssetTabId = value;

    });

    this.navigationService.showBackButton
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((backButton) => {
        this.showBackButton = backButton;
      });

  }

  getTitleInHeader() {

  }

  getProjectAssetTabsInOrder() {
    return this.projectAssetTabs.sort((a, b) => a.ordering - b.ordering);
  }

  resetTabs() {
    this.showTabs = false;
  }

  enableTabs() {
    this.showTabs = true;
  }

  getLatestVersionId(index: number) {
    if (
      this.projectAssetTabs[index].versions &&
      this.projectAssetTabs[index].versions?.length
    ) {
      return this.projectAssetTabs[index].versions?.[0].id;
    } else {
      return null;
    }
  }


  clickOnBackLink() {

    this.navigationProjectId = this.navigationService.navigationProjectId;
    this.navigationProjectAssetId = this.navigationService.navigationProjectAssetId;
    this.navigationProjectAssetTabId = this.navigationService.navigationProjectAssetTabId;


    if (
      this.navigationService.navigationProjectId &&
      this.navigationService.navigationProjectAssetId &&
      this.navigationService.navigationProjectAssetTabId
    ) {
      this.router.navigate(['/projects/assets/' + this.navigationProjectId + '/' + this.navigationProjectAssetId + '/settings']);
    }

    if (
      this.navigationService.navigationProjectId &&
      this.navigationService.navigationProjectAssetId &&
      !this.navigationService.navigationProjectAssetTabId
    ) {
      this.router.navigate(['/projects', 'detail', this.navigationProjectId]);
    }

    if (
      this.navigationService.navigationProjectId &&
      !this.navigationService.navigationProjectAssetId &&
      !this.navigationService.navigationProjectAssetTabId
    ) {
      this.router.navigate(['/projects']);
    }

    return false;
  }

  tabIsActive(currentProjectAssetTab) {

    return currentProjectAssetTab.id === this.activeProjectAssetTabId;
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
