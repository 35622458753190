<app-modal-green-header-large [modalTitle]="'Add Project Asset'"></app-modal-green-header-large>
<div class="modal-body">
  <form [formGroup]="projectAssetForm">
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">Title *</label>
        <input formControlName="name" type="text" class="form-control vvv-input-big">
        <small class="ml-3 form-text text-white">
          Ein Überbegriff für das Projekt
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">Template</label>
        <select formControlName="projectTemplate"
                class="form-control vvv-select-big"
        >
          <option *ngFor="let projectTemplate of projectTemplates"
                  [value]="projectTemplate.id">{{projectTemplate.name}}</option>
        </select>
        <small class="form-text ">
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">Description</label>
        <textarea class="form-control" formControlName="description" rows="3"></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-6">
        <label class="modal-form-label">Product *</label>
        <select formControlName="product_id" class="form-control vvv-select-big">
          <option *ngFor="let product of products"
                  [value]="product.id">{{product.name}}</option>
        </select>
        <small class="form-text ">
        </small>
      </div>
      <div class="form-group col-lg-6">
        <label class="modal-form-label">Deadline *</label>
        <input type="text" formControlName="deadline" class="form-control vvv-input-big" bsDatepicker>
        <small class="form-text ">
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-6">
        <label class="modal-form-label">Budget *</label>
        <input
          formControlName="budget"
          type="text"
          class="form-control vvv-input-big"
          mask="0*,00"
          [dropSpecialCharacters]="false"
        >
        <small class="form-text ">
        </small>
      </div>
      <div class="form-group col-lg-6">
        <label class="modal-form-label">PM *</label>
        <select formControlName="pm_id" class="form-control vvv-select-big">
          <option *ngFor="let user of filterByUserGroup(userGroup.IS_PROJECT_MANAGER)" [value]="user.id">{{user.name}}</option>

        </select>
        <small class="form-text ">
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-6">
        <label class="modal-form-label">Moco Link</label>
        <input formControlName="linkMoco" type="text" class="form-control vvv-input-big">
        <small class="ml-3 form-text text-white">
          <div *ngIf="linkMoco?.errors?.pattern">
            Dies ist keine valide URL
          </div>
        </small>
      </div>
      <div class="form-group col-lg-6">
        <label class="modal-form-label">Hive Link</label>
        <input formControlName="linkHive" type="text" class="form-control vvv-input-big">
        <small class="ml-3 form-text text-white">
          <div *ngIf="linkHive?.errors?.pattern">
            Dies ist keine valide URL
          </div>
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <button type="submit"
                class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font text-bold"
                (click)="submitProjectAssetForm()"
                [disabled]="validateSubmitButton()"
        >Add
        </button>
      </div>
    </div>
  </form>
</div>
