import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderTitleComponent} from "./components/common/layout/header/header-title/header-title.component";
import {HeaderAvatarComponent} from "./components/common/layout/header/header-avatar/header-avatar.component";
import {HeaderUserMenuComponent} from "./components/common/layout/header/header-user-menu/header-user-menu.component";
import {LoginComponent} from "./components/user/login/login.component";
import {LogoutComponent} from "./components/user/logout/logout.component";
import {HeaderComponent} from "./components/common/layout/header/header.component";
import {
  ModalGreenHeaderLargeComponent
} from "./components/common/layout/modals/modal-green-header-large/modal-green-header-large.component";
import {RouterModule} from "@angular/router";
import {LoadingSuccessComponent} from "./components/common/layout/status/loading-success/loading-success.component";
import {LoadingDotsComponent} from "./components/common/layout/status/loading-dots/loading-dots.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LayoutComponent} from "./components/common/layout/layout.component";
import {Ability, PureAbility} from "@casl/ability";
import {AbilityModule} from "@casl/angular";
import {ModalModule} from "ngx-bootstrap/modal";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {CommentsViewComponent} from './components/comments/comments-view/comments-view.component';
import {FilesDragDropComponent} from './components/common/files/files-drag-drop/files-drag-drop.component';
import {NgxDropzoneModule} from "ngx-dropzone";
import { CommentStoryboardAddComponent } from './components/comments/comment-storyboard-add/comment-storyboard-add.component';
import { CommentsStoryboardListComponent } from './components/comments/comments-storyboard-list/comments-storyboard-list.component';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { IconCloseRoundedComponent } from './components/common/icons/icon-close-rounded/icon-close-rounded.component';
import { AvatarComponent } from './components/user/avatar/avatar.component';
import { CommentAddComponent } from './components/comments/comment-add/comment-add.component';
import { LibraryListComponent } from './components/library/library-list/library-list.component';
import { LibraryAdminListComponent } from './components/library/library-admin-list/library-admin-list.component';
import {MatTreeModule} from "@angular/material/tree";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import { InputTypeAheadComponent } from './components/common/forms/input-type-ahead/input-type-ahead.component';
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import { InputBigComponent } from './components/common/forms/input-big/input-big.component';
import { InputSmallComponent } from './components/common/forms/input-small/input-small.component';
import { SelectComponent } from './components/common/forms/select/select.component';
import { ButtonSubmitComponent } from './components/common/forms/button-submit/button-submit.component';
import { LinkAsButtonBigComponent } from './components/common/forms/link-as-button-big/link-as-button-big.component';
import { LinkAsButtonSmallComponent } from './components/common/forms/link-as-button-small/link-as-button-small.component';
import { CommentsVideoAddComponent } from './components/comments/comments-video-add/comments-video-add.component';
import { SketchComponent } from './components/library/sketch/sketch.component';
import { SafePipe } from './pipes/safe.pipe';
import { WelcomeComponent } from './components/user/welcome/welcome.component';
import { FilesSingleFileUploadComponent } from './components/common/files/files-single-file-upload/files-single-file-upload.component';
import { CommentInlineViewComponent } from './components/comments/comment-inline-view/comment-inline-view.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';



@NgModule({
  declarations: [
    HeaderTitleComponent,
    HeaderAvatarComponent,
    HeaderUserMenuComponent,
    HeaderComponent,
    LoginComponent,
    LogoutComponent,
    HeaderComponent,
    ModalGreenHeaderLargeComponent,
    LoadingSuccessComponent,
    LoadingDotsComponent,
    LayoutComponent,
    CommentsViewComponent,
    FilesDragDropComponent,
    CommentStoryboardAddComponent,
    CommentsStoryboardListComponent,
    DragDropFileUploadDirective,
    IconCloseRoundedComponent,
    AvatarComponent,
    CommentAddComponent,
    LibraryListComponent,
    LibraryAdminListComponent,
    InputTypeAheadComponent,
    InputBigComponent,
    InputSmallComponent,
    SelectComponent,
    ButtonSubmitComponent,
    LinkAsButtonBigComponent,
    LinkAsButtonSmallComponent,
    CommentsVideoAddComponent,
    SketchComponent,
    SafePipe,
    WelcomeComponent,
    FilesSingleFileUploadComponent,
    CommentInlineViewComponent,
    Nl2brPipe,
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        AbilityModule,
        ModalModule.forRoot(),
        BsDropdownModule,
        NgxDropzoneModule,
        MatProgressSpinnerModule,
        FormsModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        AutocompleteLibModule
    ],
    exports: [
        ModalGreenHeaderLargeComponent,
        CommentsViewComponent,
        FilesDragDropComponent,
        CommentStoryboardAddComponent,
        CommentsStoryboardListComponent,
        IconCloseRoundedComponent,
        AvatarComponent,
        CommentAddComponent,
        InputTypeAheadComponent,
        CommentsVideoAddComponent,
        LinkAsButtonSmallComponent,
        Nl2brPipe,
        CommentInlineViewComponent
    ],
  providers: [
    {provide: Ability, useValue: new Ability()},
    {provide: PureAbility, useExisting: Ability},
    SafePipe
  ]
})
export class SharedModule {
}
