import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from "../../../shared/services/layout.service";
import {Subject} from "rxjs";
import {ProjectService} from "../../../shared/services/project.service";
import {takeUntil} from "rxjs/operators";
import {Project} from "../../../shared/models/project";
import {ToastrService} from "ngx-toastr";
import {PinService} from "../../../shared/services/pin.service";
import {ProjectAssetTabsRepository} from "../../../shared/stores/projectAssetTabs/projectAssetTabs.repository";
import {Navigation} from "@angular/router";
import {NavigationService} from "../../../shared/services/navigation.service";

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.sass']
})
export class ProjectDashboardComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();

  public projects: Project[] = [];

  public pinnedProjects;

  public openedProjectAssetsOfProject: string[] = [];

  constructor(
    private layoutService: LayoutService,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private pinService: PinService,
    private projectAssetTabsRepository: ProjectAssetTabsRepository,
    private navigationService: NavigationService
  ) {

  }

  ngOnInit(): void {
    this.layoutService.setShowBigTitleInHeader(true);
    this.layoutService.setTitle('Projects');
    this.projectAssetTabsRepository.deleteAllEntities();

    this.navigationService.setParams(null,null,null);

    this.getPinnedProjectIds();
    this.projectService.getProjectList().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (response) => {
        this.projects = response;
      },
      (error) => {
        this.toastrService.error('Could not get Projects & Assets');
      }
    );
  }

  toggleDisplayOpenendProjectAssetsOfProject(projectId) {
    if(this.openedProjectAssetsOfProject && Array.isArray(this.openedProjectAssetsOfProject)) {
      const index = this.openedProjectAssetsOfProject.indexOf(projectId);

      if (projectId && index > -1) {
        this.openedProjectAssetsOfProject.splice(index, 1);
      } else {
        this.openedProjectAssetsOfProject.push(projectId);
      }
    }
  }

  showProjectAssets(projectId) {
    return projectId && this.openedProjectAssetsOfProject?.indexOf(projectId) > -1;

  }

  addAsPinned(projectId) {
    this.pinService.addPin(projectId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.pinnedProjects.push({project_id:projectId});
        this.toastrService.success('Pin status has been saved');
      },
      (error) => {
        this.toastrService.error('Could not save Pin status');
      }
    );
  }

  getPinnedProjectIds() {
    this.pinService.getPinnedProjectIds().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (pinnedProjectIds) => {
        this.pinnedProjects = pinnedProjectIds;
      },
      (error) => {
        this.toastrService.error('could not get pinned project list');
      }
    );
  }

  checkIfIsPinned(projectId) {
    if(Array.isArray(this.pinnedProjects)) {
      return this.pinnedProjects.find((pinnedProject) => {
        return pinnedProject.project_id === projectId;
      });

      // return foundPinnedProject && foundPinnedProject.length > -1;
    }else{
      return false;
    }
  }

  removePinned(projectId) {
    this.pinService.removePin(projectId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Pin has been removed');

        this.pinnedProjects.forEach((value, i) => {
          if(value.project_id === projectId) {
            this.pinnedProjects.splice(i, 1);
          }
        });
      },
      (error) => {
        this.toastrService.error('Pin could not be removed');
      }

    );
  }

  copyLinkToClipboard(link: string) {
    navigator.clipboard.writeText(location.origin + '/' +link);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
