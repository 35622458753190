import { Injectable } from '@angular/core';
import {count, switchMap} from 'rxjs/operators';
import {select, withProps, createStore, setProp, setProps} from '@ngneat/elf';
import { Comment } from '../../models/comment';
import {
  addEntities,
  selectAllEntities,
  selectAllEntitiesApply, setEntitiesMap, updateAllEntities,
  updateEntities,
  deleteAllEntities,
  withEntities, deleteEntities, setEntities,
} from '@ngneat/elf-entities';
import {User} from "../../models/user";


export interface CommentProps{
  filter: 'ALL' | 'INTERNAL' | 'ACTIVE'
}

const store = createStore(
  {name: 'comments'},
  withProps<CommentProps>({filter:'ALL'}),
  withEntities<Comment>()
);

const comments$ = store.pipe(select((state) => state));

// export const dataSource = createRequestDataSource({
//   data$: store.pipe(selectAllEntities()),
//   requestKey: 'comments',
//   dataKey: 'comments',
//   store
// });

@Injectable({providedIn: 'root'})
export class CommentsRepository {
  comments$ = store.pipe(selectAllEntities());
  deleteAllEntities() {
    store.update(deleteAllEntities());
  }

  addSingleComment(comment) {
    store.update(addEntities(comment));
  }

  addComments(
    comments: Comment[]
  ) {
    comments.forEach((comment) => {
      store.update(
        addEntities({
          id: comment.id,
          content: comment.content,
          user_id: comment.user_id,
          project_id: comment.project_id,
          internal: comment.internal,
          user: comment.user,
          created_at: comment.created_at,
          projectAsset_id: comment.projectAsset_id,
          comment_tab_id: comment.comment_tab_id,
          commentable_id: comment.commentable_id,
          frameNumber: comment.frameNumber,
          frame_number: comment.frame_number,
          markerPosX: comment.markerPosX,
          markerPosY: comment.markerPosY,
          markerPosT: comment.markerPosT,
          markerPosRefWidth: comment.markerPosRefWidth,
          markerPosRefHeight: comment.markerPosRefHeight,
          markerPosYOrg: comment.markerPosY,
          markerPosXOrg: comment.markerPosX,
          frame_id: comment.frame_id,
          done: comment.done
        })
      );
    });

  }

  updateCommentsList(
    comments: any
  ) {
    store.update(setEntitiesMap(comments));
  }

  updateStore()
  {
    store.update(updateAllEntities((entity) => ({...entity, count: 2})));
  }

  loadComments(comments:Comment[]) {
    comments.forEach((comment) => {
      store.update(
        addEntities({
          id: comment.id,
          content: comment.content,
          user_id: comment.user_id,
          project_id: comment.project_id,
          internal: comment.internal,
          user: comment.user,
          created_at: comment.created_at,
          projectAsset_id: comment.projectAsset_id,
          comment_tab_id: comment.comment_tab_id,
          commentable_id: comment.commentable_id,
          frame_number: comment.frame_number,
          markerPosX: comment.markerPosX,
          markerPosY: comment.markerPosY,
          markerPosYOrg: comment.markerPosY,
          markerPosXOrg: comment.markerPosX,
          markerPosT: comment.markerPosT,
          markerPosRefWidth: comment.markerPosRefWidth,
          markerPosRefHeight: comment.markerPosRefHeight,
          frame_id: comment.frame_id,
          done: comment.done
        })
      );
    });
  }

  updateSingleEntity(id, field, value) {
    store.update(updateEntities(
      id, { [field]: value}
    ));
  }

  deleteSingleEntity(id: Comment['id']) {
    store.update(deleteEntities(id));
  }
}
