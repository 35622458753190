import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ModalModule} from "ngx-bootstrap/modal";
import {DemoComponent} from './shared/components/common/demo/demo.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "./shared/interceptors/auth.interceptor";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProjectDashboardComponent} from './components/projects/project-dashboard/project-dashboard.component';
import {ProjectAddComponent} from './components/projects/project-add/project-add.component';
import {AbilityModule} from '@casl/angular';
import {Ability, PureAbility} from '@casl/ability';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {
  ProjectAssetAddComponent
} from './components/projects/projectAsset/project-asset-add/project-asset-add.component';
import {UserProfileComponent} from './components/users/userProfile/user-profile.component';
import {SharedModule} from "./shared/shared.module";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DragulaModule} from 'ng2-dragula';
import {environment} from "../environments/environment";
import {enableElfProdMode} from "@ngneat/elf";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {LibraryComponent} from './components/library/library.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {VimeModule} from "@vime/angular";
import {NgxMaskModule} from "ngx-mask";
import { TemplatesComponent } from './components/templates/templates.component';
import { AddProjectAssetTemplateComponent } from './components/templates/add-project-asset-template/add-project-asset-template.component';
import { TruncatePipe } from './truncate.pipe';
import {TooltipModule} from "ngx-bootstrap/tooltip";


if(environment.production) {
  enableElfProdMode();
}

@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
    ProjectDashboardComponent,
    ProjectAddComponent,
    ProjectAssetAddComponent,
    UserProfileComponent,
    LibraryComponent,
    TemplatesComponent,
    AddProjectAssetTemplateComponent,


  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        AbilityModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        BsDatepickerModule.forRoot(),
        SharedModule,
        FormsModule,
        DragDropModule,
        DragulaModule.forRoot(),
        AutocompleteLibModule,
        FontAwesomeModule,
        VimeModule,
        NgxMaskModule.forRoot(),
        TooltipModule
    ],
  providers: [

    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: Ability, useValue: new Ability()},
    {provide: PureAbility, useExisting: Ability},
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
