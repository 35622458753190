import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {LayoutService} from "../../../services/layout.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    public layoutService: LayoutService
  ) { }

  ngOnInit(): void {
      this.authService.doLogout();
  }

}
