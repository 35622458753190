<ng-container *ngIf="comments.length === 0">
  <div class="col-lg-12 text-align-center mt-5">
    <h3>No Comments yet</h3>
    <p>Be the first to share what you think!</p>
  </div>
</ng-container>
<ng-container *ngIf="comments.length > 0">
  <div class="scrollable-y mediabar-vidoboard-comments mt-3">
    <ng-container *ngFor="let comment of comments; let i = index">
      <ng-container *ngIf="comment.id && !comment.new">
        <div class="row" [ngClass]="{ 'comment-done': comment.done}">
          <div class="col-lg-1">
            <div class="frame-info
                        text-align-center
                        justify-content-center
                        text-uppercase
                        mb-2"
                 *ngIf="comment.frame_number || comment.markerPosT"
            >
              <ng-container *ngIf="comment.frame_number">
                {{comment.frame_number}}
              </ng-container>
              <ng-container *ngIf="comment.markerPosT">
                <a (click)="clickOnVideoTimeStamp(comment.markerPosT)">{{getTimeFormat(comment.markerPosT)}}</a>
              </ng-container>
            </div>
          </div>
          <div class="col-lg-2 pt-3 text-align-center">
            <app-avatar [sizeSmall]="true"></app-avatar>
          </div>
          <div class="col-lg-7 pt-3">{{comment.user?.name}} <br/>{{comment.created_at | date}}</div>
          <div class="col-lg-2 pt-3 pr-2">
            <ng-container *ngIf="!comment.done">
              <img src="assets/icons/icon_comment_undone.svg"
                   (click)="markCommentAsDone(comment)"
                   class="mouse-pointer"
                   alt="Mark Comment as done"
              />
            </ng-container>
            <ng-container *ngIf="comment.done">
              <img src="assets/icons/icon-done.svg"
                   (click)="markCommentAsDone(comment)"
                   class="mouse-pointer"
                   alt="Mark Comment as undone"
              />
            </ng-container>
            <img src="assets/icons/icon-delete-middle-grey.svg"
                 alt="delete Comment"
                 title="delete Comment"
                 class="icon-delete mouse-pointer"
                 (click)="deleteComment(comment)"
            />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-12 mt-3" [ngClass]="{ 'comment-done': comment.done}">
            {{comment.content}}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
