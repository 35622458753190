import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ProjectAsset} from "../models/projectAsset";
import {tap} from "rxjs/operators";

const endpointCreateProjectAsset = environment.apiUrl + 'projectAssets/store/--projectId--';
const endpointGetProjectAsset = environment.apiUrl + 'projectAssets/show/--projectId--/--assetId--';
const endpointAddTabToProjectAsset = environment.apiUrl + 'projectAssets/addTab/--projectId--/--assetId--';
const endpointUpdateProjectAsset = environment.apiUrl + 'projectAssets/update/--projectId--/--assetId--';
const endpointAssetsPerProjectId = environment.apiUrl + 'projectAssets/getAssetsPerProjectId/--projectId--';
const endpointDeactivateProjectAsset =  environment.apiUrl + 'projectAssets/deactivateProjectAsset';

@Injectable({
  providedIn: 'root'
})
export class ProjectAssetService {

  constructor(
    private http: HttpClient
  ) {
  }

  createProjectAsset(projectAsset: ProjectAsset, projectId: string) {
    const endpointUrl = endpointCreateProjectAsset.replace('--projectId--', projectId);
    return this.http.post<any>(endpointUrl, {...projectAsset}).pipe(
      tap(res => res)
    );
  }

  getProjectAssetSettings(projectId: string, assetId: string) {
    let url = endpointGetProjectAsset.replace('--projectId--', projectId);
    url = url.replace('--assetId--', assetId);

    return this.http.get<any>(url).pipe(
      tap(res => res)
    );

  }

  addNewTabToProjectAsset(projectId: string, projectAssetId: string, projectTabTemplateId: string) {
    let url = endpointAddTabToProjectAsset.replace('--projectId--', projectId);
    url = url.replace('--assetId--', projectAssetId);
    return this.http.post(url, {projectId, projectAssetId, projectTabTemplateId}).pipe(
      tap(res => res)
    );
  }

  updateProjectAsset(projectId: string, projectAssetId: string, data) {
    const url = endpointUpdateProjectAsset.replace('--projectId--', projectId);
    const endPointUrl = url.replace('--assetId--', projectAssetId);

    return this.http.post(endPointUrl, {...data} ).pipe(
      tap(res => res)
    );

  }

  getAssetsByProjectId(projectId: string) {
    const url = endpointAssetsPerProjectId.replace('--projectId--', projectId);
    return this.http.get<any>(url).pipe(
      tap(res => res)
    );
  }

  deactivateProjectAsset(projectAssetId: string) {
    return this.http.put(endpointDeactivateProjectAsset, {projectAssetId}).pipe(
      tap(res => res)
    );
  }



}
