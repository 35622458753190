import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {CommentService} from "../../../../../shared/services/comment.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Frame} from "../../../../../shared/models/frame";
import {Comment} from "../../../../../shared/models/comment";
import {CommentsRepository} from "../../../../../shared/stores/comments/comments.repository";
import {DOCUMENT} from "@angular/common";
import {FrameService} from "../../../../../shared/services/frame.service";

@Component({
  selector: 'app-project-asset-storyboard-modal-view',
  templateUrl: './project-asset-storyboard-modal-view.component.html',
  styleUrls: ['./project-asset-storyboard-modal-view.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectAssetStoryboardModalViewComponent implements OnInit, OnDestroy {
  @ViewChild('storyboardImage', {static: false}) public storyboardImage: ElementRef = {} as ElementRef;
  public projectId!: string;
  public assetId?: string;
  public versionId!: string;
  public sceneId!: string;
  public frameId!: string;
  public imageUrl!: string;
  public loading = true;
  public comments;
  public frame!: Frame;
  public frameNumber!: string;
  public projectAssetId!: string;
  public projectAssetTabId!: string;

  public commentsRepositoryComments: any;

  public ngUnsubscribe = new Subject();

  public hideMarks = false;
  public disableAddCommentByClicking = false;

  public newComment: Comment = new Comment();
  public currentComments: Comment[] = [];
  public cursorX: number = 0;
  public cursorY: number = 0;
  public markerPosRefWidth: any;
  public markerPosRefHeight: any;

  public popOver: any;

  @ViewChild('boundary') boundary!: ElementRef;
  @ViewChild('imageContainer') imageContainer!: ElementRef;
  @ViewChild('storyboardImage') storyBoardImage!: ElementRef;

  constructor(
    public bsModalRef: BsModalRef,
    private commentService: CommentService,
    private toastrService: ToastrService,
    private commentsRepository: CommentsRepository,
    @Inject(DOCUMENT) document: Document,
    private frameService: FrameService
  ) {
  }

  ngOnInit(): void {
    this.commentService.getCommentsForModalInStoryboard(this.frameId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (comments) => {
        this.loading = false;
        this.updateCommentStore();
      },
      (error) => {
        this.toastrService.error('Could not load comments in Modal');
      }
    );


  }

  updateCommentStore() {
    setTimeout(() => {
      this.commentsRepositoryComments = this.commentsRepository.comments$.subscribe((comments) => {
        this.comments = comments;
        this.filterCurrentComments();
      });
    } ,1);
  }

  filterCommentsForCurrentFrame() {
    // console.log('this.commments', this.comments);
    if(this.comments) {
      //todo: this needs to be changed for sure
      this.recalculateCurrentCommentPosition(this.comments);
      return this.comments.filter((comment) => {
        return !comment.new && comment.frame_id === this.frameId;
      });
    }

    return [];
  }


  clickedOnImageAddMarker(event) {
    if(!this.disableAddCommentByClicking) {
      const imageElementContainer = document.getElementById('image-container');
      const imageElementBoundary = document.getElementsByClassName('image-boundary');
      const imageElement = document.getElementById('storyboard-image');

      let imageElementClientHeight = 0;
      let imageElementClientWidth = 0;

      if(imageElement) {
        imageElementClientHeight = this.boundary.nativeElement.clientHeight;
        imageElementClientWidth = this.boundary.nativeElement.clientWidth;

      }
      if (imageElementContainer && imageElementBoundary) {
        let x = event.clientX - imageElementContainer.getBoundingClientRect().left;
        let y = event.clientY - imageElementContainer.getBoundingClientRect().top;

        if (x > 10) {
          x = x - 15;
        }
        if (y > 10) {
          y = y - 10;
        }

        const coordinates = {
          x,
          y
        };

        if (imageElementContainer) {
          this.cursorX = x;
          this.cursorY = y;
          this.markerPosRefWidth = imageElementClientWidth;
          this.markerPosRefHeight = imageElementClientHeight;
          this.newComment.id = Date.now().toString();
          this.newComment.new = true;
          this.newComment.markerPosXOrg = this.cursorX;
          this.newComment.markerPosYOrg = this.cursorY;
          this.newComment.markerPosX = this.cursorX;
          this.newComment.markerPosY = this.cursorY;
          this.newComment.markerPosRefWidth = this.boundary.nativeElement.clientWidth;
          this.newComment.markerPosRefHeight = this.boundary.nativeElement.clientHeight;
          this.newComment.frame_id = this.frameId;
          this.newComment.frame_number = this.frameNumber;
          this.newComment.projectAsset_id = this.projectAssetId;
          this.newComment.project_id = this.projectId;
          this.newComment.projectVersion_id = this.versionId;
          this.commentsRepository.addSingleComment(this.newComment);
        }
      }
    }
  }

  filterCurrentComments() {
    this.currentComments = this.comments.filter((comment) => {
      return comment.frame_id === this.frameId;
    });
  }

  getCommentsWithMarker() {
    if(this.currentComments) {
      return this.currentComments.filter((comment) => {
        return (comment.markerPosX && true);
      });

    }
    return [];
  }

  recalculateCurrentCommentPosition(comments) {
  //todo: check why this does not trigger when drag & drop
    //todo: after new comment added markerPosX and markerPosY are set to NaN
    const block = false;
    if (this.boundary) {
      comments.forEach((comment) => {
        let top = parseFloat(comment.markerPosYOrg);
        let left = parseFloat(comment.markerPosXOrg);
        const markerPosRefWidth = parseInt(comment.markerPosRefWidth);
        const markerPosRefHeight = parseInt(comment.markerPosRefHeight);
        let currentWidthOfImage = this.boundary.nativeElement.clientWidth;
        const middleOfCurrentImageDisplayed = currentWidthOfImage / 2;
        const middleOfContainerImage = this.imageContainer.nativeElement.clientWidth / 2;

        //if the height of a window is reduced the aspect ratio of the position of the element needs to be adapted according to the width of the container
        // invert the number in order to get the inverted value
        let offsetWidth = (middleOfContainerImage - middleOfCurrentImageDisplayed);
        // console.log('('+left+' / '+ markerPosRefWidth +') * '+ parseInt(this.boundary.nativeElement.clientWidth) +' + '+ offsetWidth);
        comment.markerPosRefHeight = markerPosRefHeight;
        comment.markerPosRefWidth = markerPosRefWidth;
        comment.markerPosX = (left / markerPosRefWidth) * parseInt(this.boundary.nativeElement.clientWidth) + offsetWidth;
        comment.markerPosY = (top / markerPosRefHeight) * parseInt(this.boundary.nativeElement.clientHeight);

      });
    }
  }

  getCurrentCommentPosition(comment) {
    return {top: comment.markerPosY + 'px', left: comment.markerPosX + 'px'};

  }

  disableAddNewComment(event) {
    this.disableAddCommentByClicking = true;
  }

  enableAddNewComment(event) {
    this.disableAddCommentByClicking = false;
  }



  commentDropped(event, comment) {

    this.disableAddCommentByClicking = false;

    const freeDragPosition = event.source.getFreeDragPosition();
    let realPositionX = parseFloat(comment.markerPosX) + freeDragPosition.x;
    let realPositionY = parseFloat(comment.markerPosY)+ freeDragPosition.y;

    if(!comment.new) {
      this.commentService.updateStoryBoardCommentPosition(
        comment.id,
        realPositionX,
        realPositionY,
        this.boundary.nativeElement.clientWidth,
        this.boundary.nativeElement.clientHeight,
      )
        .pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(
        (updatedComment: any) => {
          this.commentsRepository.updateSingleEntity(updatedComment.id, 'markerPosX', updatedComment.markerPosX);
          this.commentsRepository.updateSingleEntity(updatedComment.id, 'new', false);
          this.commentsRepository.updateSingleEntity(updatedComment.id, 'markerPosY', updatedComment.markerPosY);
          this.commentsRepository.updateSingleEntity(updatedComment.id, 'markerPosRefHeight', this.boundary.nativeElement.clientHeight);
          this.commentsRepository.updateSingleEntity(updatedComment.id, 'markerPosRefWidth', this.boundary.nativeElement.clientWidth);
          this.updateCommentStore();
        },
        (error) => {
          this.toastrService.error('Could not save position of marker');
        }
      );

    }else{
      this.commentsRepository.updateSingleEntity(comment.id, 'markerPosX', comment.markerPosX);
      this.commentsRepository.updateSingleEntity(comment.id, 'markerPosY', comment.markerPosY);
      this.commentsRepository.updateSingleEntity(comment.id, 'markerPosRefHeight', this.boundary.nativeElement.clientHeight);
      this.commentsRepository.updateSingleEntity(comment.id, 'markerPosRefWidth', this.boundary.nativeElement.clientWidth);
      this.updateCommentStore();
    }
  }

  switchHideMarks() {
    this.hideMarks = !this.hideMarks;
  }

  markComment(comment) {
    this.commentService.updateCommentDoneStatus(comment).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Changed comment status');
        this.commentsRepository.updateSingleEntity(comment.id, 'done', !comment.done);
      },
      (error) => {
        this.toastrService.error('Error while saving comment');
      }
    );
  }

  deleteComment(comment: Comment) {
    if (confirm('Are you sure you want to delete this comment?')) {
      this.commentService.deleteComment(
        comment.id,
        this.projectId,
        this.projectAssetId
      ).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (success) => {
          // this.toastrService.success('Comment has been deleted');
          this.commentsRepository.deleteSingleEntity(comment.id);
        },
        (error) => {
          this.toastrService.error('Comment has not been deleted');
        }
      );
    }
  }

  clickNextPrev(direction: string) {
    this.loading = true;
    this.frameService.getNextPrevFrame(this.frameId, direction).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (nextFrame: any) => {
        console.log(nextFrame);
        if(!nextFrame.end) {
          this.updateCommentStore();
          this.sceneId = nextFrame.frame.scene_id;
          this.frameId = nextFrame.frame.id;
          this.imageUrl = nextFrame.frame.projectFile ?? '/assets/img/placeholder-storyboard.svg';
          this.disableAddCommentByClicking = !nextFrame.frame.projectFile;
          this.frame = nextFrame.frame;
          this.frameNumber = nextFrame.frame.frameNumber;
          this.loading = false;
        }else{
          this.loading = false;
          this.bsModalRef.hide();
        }
      },
      (error) => {
        this.toastrService.error('Could not load next frame');
      }
    );
  }

  openPopover(pop) {
    this.popOver = pop;
  }

  hidePopover() {
    this.popOver.hide();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
