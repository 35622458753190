import {Component, Input, OnInit} from '@angular/core';
import {SafePipe} from "../../../pipes/safe.pipe";

@Component({
  selector: 'app-sketch',
  templateUrl: './sketch.component.html',
  styleUrls: ['./sketch.component.sass']
})
export class SketchComponent implements OnInit {

  @Input() frameSizeWidth = 1920;
  @Input() frameSizeHeight = 1080;
  public basicUrl = 'https://vervievas.paintserver.com/';

  constructor(private safePipe: SafePipe) {
    this.basicUrl = this.basicUrl + '?' + 'width=' + this.frameSizeWidth + '&height=' + this.frameSizeHeight;
    console.log(this.basicUrl);

  }

  ngOnInit(): void {
  }

}
