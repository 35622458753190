import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, interval, Subscription} from "rxjs";
import {startWith, switchMap, tap} from "rxjs/operators";

const pollingEndpoint = environment.apiUrl + 'jobs/--projectId--/--versionId--';
const endpointWorkerTasks = environment.apiUrl + 'workers/getList/--versionId--';
const endpointWorkerTasksRunning = environment.apiUrl + 'workers/checkWorkerTasks/--projectId--/--versionId--';

@Injectable({
  providedIn: 'root'
})
export class PollingService {

  public pollingQueue: any[] = [];
  public timeInterval: Subscription = new Subscription();
  public status = new BehaviorSubject(false);
  public pollingRunning = new BehaviorSubject(false);


  constructor(
    private http: HttpClient
  ) {
    // this.startPolling();
  }

  getPollingStatusAsset(projectId, versionId, workerTaskId) {
      let url = pollingEndpoint.replace('--projectId--', projectId);
      url = url.replace('--versionId--', versionId);
      return this.http.get<any>(url, {observe: 'response'});
  }

  addToPolling(pollingEntries) {
    this.pollingQueue.push(pollingEntries);
  }

  startPolling() {
    this.pollingRunning.subscribe((pollingRunning) => {
      if(pollingRunning && this.pollingQueue.length > -1) {
        console.log('hi there');
        this.pollingQueue.forEach((pollingRequest) => {
          this.timeInterval = interval(2000)
            .pipe(
              startWith(0),
              switchMap(() => this.getPollingStatusAsset(
                  pollingRequest.projectID,
                  pollingRequest.versionId,
                  pollingRequest.workerTaskId
                )
              )
            ).subscribe(
              res => this.status = res.body['completed'],
              err => console.log('HTTP Error', err)
            );
        });
      }
    });
  }

  addToPollingQueue(projectId, versionId, workerTaskId) {
    this.pollingQueue.push({
      projectId,
      versionId,
      workerTaskId
    });
  }

  removeFromPollingQueue(workerTaskId) {
    const index = this.pollingQueue.indexOf((pollingQueueEntries) => {
      return pollingQueueEntries.workerTaskId === workerTaskId;
    });
    this.pollingQueue.splice(index,1);
  }

  setPollingRunningStatus(value) {
    this.pollingRunning.next(value);
  }

  checkWorkerTasks(versionId, workingRunnerTasks) {
    const url = endpointWorkerTasks.replace('--versionId--', versionId);
    return this.http.post<[]>(url, {tasks: workingRunnerTasks}).pipe(
      tap( res => res)
    );
  }

  getWorkerTasksRunning(projectId: string, versionId: string) {
    const url = endpointWorkerTasksRunning.replace('--versionId--', versionId);
    const url2 = url.replace('--projectId--', projectId);
    const url3 = url2 + '?vers=' + new Date().getTime(); // to avoid caching
    return this.http.get(url3).pipe(
      tap(res => res)
    );
  }

}
