import { Component, OnInit } from '@angular/core';
import {User} from "../../../../../models/user";
import {AuthService} from "../../../../../services/auth.service";
import {ProjectAddComponent} from "../../../../../../components/projects/project-add/project-add.component";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-header-user-menu',
  templateUrl: './header-user-menu.component.html',
  styleUrls: ['./header-user-menu.component.sass']
})
export class HeaderUserMenuComponent implements OnInit {

  public user: User | undefined;
  modalRef?: BsModalRef;
  constructor(
    private authService : AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.currentUser;
  }

  onHidden(): void {

  }
  onShown(): void {

  }
  isOpenChange(): void {

  }

  addProject(e: Event) {
    e.preventDefault();
    const modalConfig: ModalOptions = {
      initialState: {
      },
      class: 'modal-green test'
    };
    this.modalRef = this.modalService.show(
      ProjectAddComponent,
      modalConfig
    );
  }



  logout(e: Event){
    e.preventDefault();
    this.authService.doLogout();
  }

}
