<div class="bg-light-grey header-title-margin">
  <div [ngClass]="{'container': !containerFluid, 'container-fluid': containerFluid}">
    <div [ngClass]="{'row-header-title-small': !showBigTitle, 'row-header-title': showBigTitle}"
         class="row align-items-center">
      <div class="col-auto">
        <h2 class="mt-0 mb-0 p-0 mr-4">
          <i class="fa-solid fa-chevron-left back-button mouse-pointer" *ngIf="showBackButton" (click)="clickOnBackLink()"></i>
          <span class="header-title">
            <ng-container *ngIf="('create' | able: 'project') && navigationProjectAssetTabId">
              <a [routerLink]="['projects/assets/' + navigationProjectAssetTabId]">{{ currentTitle }}</a>
            </ng-container>
            <ng-container *ngIf="!('create' | able: 'project') || !navigationProjectAssetTabId">
              {{currentTitle}}
            </ng-container>
          </span>
        </h2>
      </div>

      <ng-container class="col-auto" *ngIf="projectAssetTabs.length > 0 && !this.loading && showTabs">
        <ul class="col-auto m-0 p-0">
          <li class="mr-3 d-inline" *ngFor="let projectAssetTab of getProjectAssetTabsInOrder(); let i = index">
            <button *ngIf="!projectAssetTab.usersPage" class="
              rounded-pill
              btn
              btn-primary
              text-small-font
              background-white
              text-vvv-green-main
              "
                    [routerLink]="[
                    '/projects/assets/',
                    projectAssetTab.config?.type,
                    projectAssetTab.projectId,
                    projectAssetTab.asset_id,
                    projectAssetTab.id,
                    getLatestVersionId(i)
                    ]"
                    [ngClass]="{'active-button': tabIsActive(projectAssetTab)}"
            >
              {{projectAssetTab.config.name}}
            </button>
            <button *ngIf="projectAssetTab.usersPage && ('create' | able: 'project')"
              class="
              rounded-pill
              btn
              btn-primary
              text-small-font
              background-white
              text-vvv-green-main
              "
              [routerLink]="['/projects/users/' + projectAssetTab.projectId]"
            >
              Users
            </button>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
