import {createStore, select} from '@ngneat/elf';
import {addEntities, deleteAllEntities, selectAllEntities, setEntitiesMap, withEntities,} from '@ngneat/elf-entities';
import {ProjectAssetTab} from "../../models/projectAssetTab";
import {Injectable} from "@angular/core";

const store = createStore(
  {name: 'comments'},
  withEntities<projectAssetTabInterface>()
);

const projectAssetTabs$ = store.pipe(select((state) => state));

export interface projectAssetTabInterface {
  id: string;
  name: string;
  asset_id: string;
  config: string;
  active: boolean;
  created_at: string;
  ordering: number;
  modified: string;
  versions: string;
  projectId: string;
  usersPage: boolean;
}

@Injectable({providedIn: 'root'})
export class ProjectAssetTabsRepository {
  private projectId: string = '';
  projectAssetTabs$ = store.pipe(selectAllEntities());

  updateProjectAssetTabList(
    projectAssetTabs: any
  ) {
    store.update(setEntitiesMap(projectAssetTabs));

  }

  deleteAllEntities(){
    store.update(deleteAllEntities());
  }

  loadProjectAssetTabs(
    projectAssetTabs: any[]
  ) {

    projectAssetTabs.forEach((projectAssetTab) => {
        store.update(addEntities(
          {
            id: projectAssetTab.id,
            name: projectAssetTab.name,
            asset_id: projectAssetTab.asset_id,
            config: projectAssetTab.config,
            active: projectAssetTab.active,
            created_at: projectAssetTab.created_at,
            ordering: projectAssetTab.ordering,
            modified: projectAssetTab.modified,
            versions: projectAssetTab.versions,
            projectId: this.projectId,
            usersPage: projectAssetTab.usersPage
          }
        ));
      }
    )
  }

  resetProjectAssetTabsStore() {
    store.update(deleteAllEntities());
  }

  setProjectId(projectId: string) {
    this.projectId = projectId;
  }
}
