import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.sass']
})
export class DemoComponent {

  public  modalRef: BsModalRef | undefined;

  constructor(private BsModalService: BsModalService) {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.BsModalService.show(template, {class: 'modal-xl'});
  }

}
