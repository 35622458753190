import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {
  AbstractControl,
  FormBuilder, FormControl,
  FormGroup,
  FormGroupDirective, NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LayoutService} from "../../../services/layout.service";
import {ErrorStateMatcher} from "@angular/material/core";
import {UserService} from "../../../services/user.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.sass']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  public title = environment.applicationName;
  public companyName = environment.companyName;
  public loginForm: FormGroup;
  public submitButtonTouched = false;
  public loginSuccess = false;
  public waitingForLoginResponse = false;
  public matcher = new MyErrorStateMatcher();
  private projectId: string = '';
  private activationToken: string = '';
  private ngUnsubscribe = new Subject();
  private email: string = '';

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.loginForm = this.fb.group({
      name: ['', [Validators.required]],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required],
    }, { validators: this.checkPasswords});
  }

  ngOnInit(): void {
    this.layoutService.setBackground(true);
    this.activationToken = this.activatedRoute.snapshot.params.activationToken;
    this.projectId = this.activatedRoute.snapshot.params.projectId;
  }

  submitUserData() {
    if (this.loginForm.valid) {
      this.userService.activateAccount(this.loginForm.value, this.projectId, this.activationToken).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (email: any) => {
           this.email = email;
           this.loginUser();
        },
        (error) => {}
      );
    }
  }

  loginUser() {
    if (this.loginForm.valid) {
      this.authService.login({email: this.email, password: this.loginForm.controls['password'].value});
      this.waitingForLoginResponse = this.authService.userLoginIsLoading;

      if (this.authService.currentUser && Object.keys(this.authService.currentUser).length !== 0) {
        this.waitingForLoginResponse = false;
        this.loginSuccess = true;
        window.location.href = '/projects/detail/'+ this.projectId;
      }
    } else {
      this.waitingForLoginResponse = false;
      this.loginSuccess = false;
    }

  }

  clickSubmitButton() {
    this.submitButtonTouched = true;
  }

  getLoginError() {
    return this.authService.userLoginFailed;
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('passwordRepeat')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
