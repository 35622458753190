import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommentService} from "../../../services/comment.service";
import {ToastrService} from "ngx-toastr";
import {Form, FormBuilder, FormControl, FormGroupDirective, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import {User} from "../../../models/user";
import {CommentsRepository} from "../../../stores/comments/comments.repository";

@Component({
  selector: 'app-comment-storyboard-add',
  templateUrl: './comment-storyboard-add.component.html',
  styleUrls: ['./comment-storyboard-add.component.sass']
})
export class CommentStoryboardAddComponent implements OnInit, OnDestroy {

  public currentDate = new Date();
  public content: string | undefined;
  public commentForm = this.fb.group({
    content: ['', Validators.required],
    internal: [0, Validators.required]
  });

  @Input()  projectId!: string;
  @Input()  projectAssetTabVersionId!: string;
  @Input()  tabId!: string;
  @Input()  sceneId!: string;
  @Input()  frameId!: string;
  @Input()  projectAssetId!: string;
  @Input()  projectAssetTabId!: string;
  @Input()  showAddComment: boolean = false;
  @Input()  frameNumber!: string;


  private ngUnsubscribe = new Subject();
  private userId!: string;

  public user: User | undefined;

  public showLoadingSpinner: boolean = false;

  constructor(
    private commentService: CommentService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private authService: AuthService,
    public commentsRepository: CommentsRepository
  ) {

  }

  ngOnInit(): void {
    this.commentsRepository.comments$.subscribe((state) => {});
    this.user = this.authService.currentUser;
  }

  saveComment(formData: any, formDirective: FormGroupDirective) {

    const content = formData.value.content;
    const internal = formData.value.internal;

    if(this.authService.currentUser.id && this.commentForm.valid) {
      this.userId = this.authService.currentUser.id;

      // this.commentService.postingComment.next(true);

      this.showLoadingSpinner = true;
      this.commentService.postCommentForStoryBoard(
        this.projectId,
        this.projectAssetId,
        this.projectAssetTabId,
        this.projectAssetTabVersionId,
        this.sceneId,
        this.frameId,
        this.userId,
        content,
        internal,
        this.frameNumber
      );
    }

    this.commentService.postingComment.subscribe(
      (success) => {
        this.showLoadingSpinner = false;
        this.toastrService.success('Comment has been saved');
        this.commentForm.controls['content'].setValue('');
        this.commentForm.controls['content'].updateValueAndValidity();
        this.commentForm.clearValidators();
      },
      (error) => {
        this.toastrService.error('Comment could not be saved');
      }
    );


  }

  checkIfContentLengthHigherThan3Chars() {
    return this.commentForm.get('content')?.value.length > 3;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
