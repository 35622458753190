<ng-container *ngIf="showAddComment">
  <form [formGroup]="commentForm" #formDirective="ngForm" class="mt-3 border-dashed-top py-3" (ngSubmit)="saveComment(commentForm, formDirective)">

    <div class="row m-0">
      <div class="col col-avatar text-center">
        <app-avatar [sizeSmall]="true"></app-avatar>
      </div>
      <div class="col-lg-8 textarea-comments-wide comments-background-color">
        <p class="username-and-time mt-2">{{user?.name}} {{currentDate | date:'dd.MM.yyyy'}}</p>
        <textarea class="textarea-comments-wide"
                  placeholder="Start typing your Comment here"
                  formControlName="content"
        ></textarea>
      </div>
      <div class="col comments-background-color pt-3">
        <div class="button-wrap">
          <button type="submit" class="vvv-input-small
                          btn
                          btn-primary
                          text-small-font
                          pr-4 pl-4 mt-1"
                  [disabled]="!showLoadingSpinner && !checkIfContentLengthHigherThan3Chars()">
            Comment
          </button>
        </div>
        <!--    <button class="vvv-input-small-->
        <!--     btn-->
        <!--     btn-primary-->
        <!--     background-white-->
        <!--     text-vvv-green-main-->
        <!--     text-small-font-->
        <!--     pl-3 pr-3-->
        <!--     ml-3-->
        <!--     button-add-template">Edit Time</button>-->
        <div class="private-button ml-4">
          <div class="label-wrap mt-2">Private</div>
          <div class="switch-wrap ml-1 mt-1">
            <div class="switch-wrap ml-3">
              <label class="switch">
                <input type="checkbox" formControlName="internal">
                <span class="slider round"></span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</ng-container>
