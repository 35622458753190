import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {LayoutService} from "../../../services/layout.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  public title = environment.applicationName;
  public companyName = environment.companyName;
  public loginForm: FormGroup;
  public submitButtonTouched = false;
  public loginSuccess = false;
  public waitingForLoginResponse = false;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public layoutService: LayoutService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.layoutService.setBackground(true);
    if(this.authService.isLoggedIn) {
      window.location.href = '/projects';
    }
  }

  loginUser() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value);
      this.waitingForLoginResponse = this.authService.userLoginIsLoading;

      if (this.authService.currentUser && Object.keys(this.authService.currentUser).length !== 0) {
        this.waitingForLoginResponse = false;
        this.loginSuccess = true;
        window.location.href = '/projects';
      }
    } else {
      this.waitingForLoginResponse = false;
      this.loginSuccess = false;
    }

  }

  clickSubmitButton() {
    this.submitButtonTouched = true;
  }

  getLoginError() {
    return this.authService.userLoginFailed;
  }

}
