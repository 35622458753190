import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../models/user";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.sass']
})
export class AvatarComponent implements OnInit {

  @Input() user!: User;
  @Input() sizeSmall: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
