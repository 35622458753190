
<div class="modal-body">
  <form>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">Current password *</label>
        <input type="password" [formControl]="password" class="form-control vvv-input-big">
        <small class="ml-3 form-text text-white">
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">New password *</label>
        <input type="password" [formControl]="newPassword" class="form-control vvv-input-big">
        <small class="ml-3 form-text text-white">
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <label class="modal-form-label">Repeat password *</label>
        <input type="password" [formControl]="newPasswordConfirmation" class="form-control vvv-input-big">
        <small class="ml-3 form-text text-white">
        </small>
      </div>
    </div>
    <div class="form-row">
      <button class="btn-primary vvv-button-white" (click)="submitPasswordChange($event)">Save</button>
    </div>
  </form>
</div>
