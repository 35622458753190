import { Component, OnInit } from '@angular/core';

// Reference for this at https://codepen.io/nzbin/pen/GGrXbp / https://nzbin.github.io/three-dots/


@Component({
  selector: 'app-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss']
})
export class LoadingDotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
