import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ProjectAssetFilesService} from "../../../../services/project-asset-files.service";
import {Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {
  ProjectAssetStoryboardModalViewComponent
} from "../../../../../components/projects/projectAsset/projectAssetTab/project-asset-storyboard-modal-view/project-asset-storyboard-modal-view.component";
import {Frame} from "../../../../models/frame";
import {SketchComponent} from "../../../library/sketch/sketch.component";
import {DomSanitizer, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-files-drag-drop',
  templateUrl: './files-drag-drop.component.html',
  styleUrls: ['./files-drag-drop.component.scss']
})


export class FilesDragDropComponent implements OnInit, OnDestroy, OnChanges {

  files: File[] = [];
  @Input() projectId!: string;
  @Input() projectAssetId!: string;
  @Input() projectAssetTabId?: any;
  @Input() tabId!: string;
  @Input() versionId = '';
  @Input() sceneId: string = '';
  @Input() frameId: string = '';
  @Input() uploadType: string = '';
  @Input() imageUrl: any = null;
  @Input() frame!: Frame;
  @Input() frameNumber!: string;

  @Input() showPlaceHolder = false;

  @Input() enablePDF = false;

  @Output() fileUploadDone = new EventEmitter();
  @Output() fileUploadRunning = new EventEmitter();
  @Output() fileUploadedUrl = new EventEmitter();
  @Output() fileResourceResponse = new EventEmitter();

  @Input() fileUploadHasBeenFinishedOutside = false;


  @Input() type: string = '';

  public uploadRunning = false;

  public modalRef?: BsModalRef;

  private ngUnsubscribe = new Subject();

  constructor(
    private toastrService: ToastrService,
    private projectAssetFileService: ProjectAssetFilesService,
    private modalService: BsModalService,
    private sanitization: DomSanitizer
  ) {

  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
  }

  getAcceptedFiles() {
    return this.enablePDF ? 'image/*, application/pdf' : 'image/*';
  }

  onFilesAdded(event: any) {
    // upload Framess
    if (
      'frame' === this.uploadType &&
      this.projectId &&
      this.projectAssetId &&
      this.tabId &&
      this.versionId &&
      this.sceneId &&
      this.frameId
    ) {
      this.uploadFrameFile(event);
    }

    if (
      'private' === this.uploadType ||
      'public' === this.uploadType
    ) {
      this.uploadProjectFile(event, this.uploadType);
    }

  }

  private fileSuccessfullyUploaded(fileResponse, event) {
    this.toastrService.success('File uploaded successfully');
    this.fileUploadDone.emit(true);
    this.fileUploadedUrl.emit(fileResponse.fileUrl);
    this.fileResourceResponse.emit(fileResponse);
    this.files.splice(this.files.indexOf(event), 1);
  }

  private uploadProjectFile(event, uploadType: string) {

    // this.files.push(...event.addedFiles);

    this.readFile(event.addedFiles[0]).then(fileContents => {
      this.fileUploadRunning.emit(true);
      this.uploadRunning = true;
      const fileName = event.addedFiles[0].name;

      this.projectAssetFileService.uploadProjectFile(
        this.projectId,
        this.projectAssetId,
        this.projectAssetTabId,
        this.versionId,
        fileContents,
        uploadType,
        fileName
      ).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (fileResponse) => {
          this.fileSuccessfullyUploaded(fileResponse, event);
        },
        (error) => {
          this.toastrService.error('Error while uploading file');
        }
      );
    });
  }

  private uploadFrameFile(event) {
    this.files.push(...event.addedFiles);
    console.log(this.files[0]);
    this.readFile(this.files[0]).then(fileContents => {
      // Put this string in a request body to upload it to an API.
      this.fileUploadRunning.emit(true);
      this.uploadRunning = true;
      let fileName = 'null';
      this.projectAssetFileService.uploadFrame(
        this.projectId,
        this.projectAssetId,
        this.tabId,
        this.versionId,
        this.sceneId,
        this.frameId,
        fileContents,
        this.files[0].name
      ).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (fileResponse) => {
          this.fileSuccessfullyUploaded(fileResponse, event);
        },
        (error) => {
          this.toastrService.error('There was an error while uplding the frame');
        }
      );
    });

  }

  private async readFile(file: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);

      };
      reader.onerror = e => {
        this.toastrService.error('Could not read file for upload');
        return reject(null);
      };

      if (!file) {
        this.toastrService.error('Could not find file to upload');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }

  clickOnImageOpenModal() {
    const modalConfig: ModalOptions = {
      initialState: {
        projectId: this.projectId,
        assetId: this.projectAssetId,
        versionId: this.versionId,
        sceneId: this.sceneId,
        frameId: this.frameId,
        imageUrl: this.imageUrl,
        frame: this.frame,
        frameNumber: this.frameNumber,
        projectAssetTabId: this.tabId
      },
      class: 'modal-light modal-dark vh-100 w-100 m-0'
    }
    this.modalRef = this.modalService.show(
      ProjectAssetStoryboardModalViewComponent,
      modalConfig
    );

  }

  openSketch() {
    const modalConfig: ModalOptions = {
      initialState: {
        projectId: this.projectId,
        assetId: this.projectAssetId,
        versionId: this.versionId,
        sceneId: this.sceneId,
        frameId: this.frameId,
        imageUrl: this.imageUrl,
        frame: this.frame,
        frameNumber: this.frameNumber,
        projectAssetTabId: this.tabId
      },
      class: 'modal-light modal-dark vh-100 w-100 m-0'
    }
    this.modalRef = this.modalService.show(
      SketchComponent,
      modalConfig
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.fileUploadHasBeenFinishedOutside) {
      this.fileUploadRunning.emit(false);
      this.fileUploadDone.emit(true);
      this.uploadRunning = false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
