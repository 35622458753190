import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public navigationProjectId;
  public navigationProjectAssetId;
  public navigationProjectAssetTabId;
  public showBackButton = new BehaviorSubject(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => { return false};
  }

  setParams(
    projectId,
    projectAssetId,
    tabId
  ) {
   this.navigationProjectId = projectId || false;
   this.navigationProjectAssetId = projectAssetId || false;
   this.navigationProjectAssetTabId = tabId || false;

   if(!this.navigationProjectId) {
     this.showBackButton.next(false);
   }else{
     this.showBackButton.next(true);
   }
  }
}
