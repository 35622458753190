<div class="row comment-inline-wrapper">
    <div class="col-lg-1" [ngClass]="{'comment-undone': !comment.done}">
    </div>
    <div class="col-lg-11">
      <div class="row">
        <div class="offset-11 col-lg-1">
          <app-icon-close-rounded class="d-inline-block" (click)="hidePopover()"></app-icon-close-rounded>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <app-avatar [sizeSmall]="true"></app-avatar>
        </div>
        <div class="col-lg-8">
          {{comment.user?.name}} | {{comment.created_at | date}}
        </div>
        <div class="col-lg-2">
          <ng-container *ngIf="!comment.done">
            <img src="assets/icons/icon_comment_undone.svg"
                 (click)="markCommentAsDone(comment)"
                 class="mouse-pointer"
                 alt="Mark Comment as done"
            />
          </ng-container>
          <ng-container *ngIf="comment.done">
            <img src="assets/icons/icon-done.svg"
                 (click)="markCommentAsDone(comment)"
                 class="mouse-pointer"
                 alt="Mark Comment as undone"
            />
          </ng-container>
          <img
            src="/assets/icons/icon-delete.svg"
            alt="Delete comment"
            title="Delete comment"
            class="mouse-pointer"
            (click)="deleteComment(comment)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          {{comment.content}}
        </div>
      </div>
    </div>
  </div>

