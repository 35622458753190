import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {UserService} from "../../../shared/services/user.service";
import {Subject} from "rxjs";
import {UserGroup} from "../../../shared/models/userGroup";
import {takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {User} from "../../../shared/models/user";
import {ProjectTemplate} from "../../../shared/models/projectTemplate";
import {Client} from "../../../shared/models/client";
import {ClientService} from "../../../shared/services/client.service";
import {ProjectService} from "../../../shared/services/project.service";
import {FormBuilder, Validators} from "@angular/forms";
import {ProductService} from "../../../shared/services/product.service";
import {Product} from "../../../shared/models/product";
import {Router} from "@angular/router";
import {TabTemplateService} from "../../../shared/services/tab-template.service";

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.sass']
})
export class ProjectAddComponent implements OnInit, OnDestroy {
  public projectId: string | undefined;
  public loadingUser = true;
  public loadingSellerAndPMs = true;
  public loadingPm = true;
  public loadingProducts = true;
  public loadingProjectTemplates = true;
  public loadingClients = true;
  ngUnsubscribe = new Subject();
  public userGroup = UserGroup;
  public users: User[] = [];
  public projectTemplates: ProjectTemplate[] = [];
  public clients: Client[] = [];
  public products: Product[] = [];
  public showErrors = false;
  public projectForm = this.fb.group({
    name: ['', Validators.required],
    pm_id: [''],
    seller_id: [''],
    client_id: [''],
    budget: [''],
    linkId1: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
    linkId2: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
    deliveryDate: ['', Validators.required],
    config: this.fb.group({
      // product_id: ['', Validators.required],
      projectTemplate: [''],
      contactPerson: [''],
      contactPersonSalutation: [''],
      description: ['']
    }),

  });

  public productList: Product[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private projectService: ProjectService,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private productService: ProductService,
    private router: Router,
    private templateService: TabTemplateService
  ) {
  }

  ngOnInit(): void {
    const getUserGroup = [this.userGroup.IS_SELLER, this.userGroup.IS_PROJECT_MANAGER];
    this.userService.getUserListByUserGroup(
      getUserGroup
    ).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (response) => {
        this.users = response;
        this.loadingSellerAndPMs = false;
      },
      (error) => {
        this.toastrService.error('Could not load UserGroup List');
        this.loadingSellerAndPMs = true;
      }
    );

    this.templateService.getTabTemplateList(['project']).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (templates) => {
        this.projectTemplates = templates;
        this.loadingProjectTemplates = false;
      },
      (error) => {
        this.toastrService.error('Could not load ProjectTemplates');
      }
    );

    this.clientService.getClientList().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (response) => {
        this.clients = response;
        this.loadingClients = false;
      },
      (error) => {
        this.toastrService.error('Could not load Clients');
        this.loadingClients = true;
      }
    );

    this.productService.getProductList().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (productList) => {
        this.products = productList;
        this.loadingProducts = false;
      },
      (error) => {
        this.toastrService.error('Could not load Products');
      }
    );

  }

  filterByUserGroup(userGroupId: string) {
    return this.users.filter((user) => {
      return user.roles?.filter((userRole) => {
        return userRole.id === userGroupId;
      });
    });
  }

  isLoading() {
    return !this.loadingUser && !this.loadingProducts && !this.loadingProjectTemplates;
  }

  onSelectPM(user) {
    this.projectForm.get('pm_id')?.setValue(user.id);
  }

  onSelectClient(client) {
    this.projectForm.get('client_id')?.setValue(client.id);
  }


  submitProject() {

    if (this.projectForm.valid) {
      this.projectService.createProject(this.projectForm.value).pipe(
        takeUntil(
          this.ngUnsubscribe
        )
      ).subscribe(
        (success) => {
          this.bsModalRef.hide();
          window.location.href = '../projects/detail/' + success.project_id;
        },
        (error) => {
          this.toastrService.error('Could not save project');
        }
      );
    } else {
      this.showErrors = true;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
