import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AuthService} from "./shared/services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  title = 'vidoBoard';

  public modalRef: BsModalRef | undefined;

  constructor(
    private BsModalService: BsModalService,
    private authService: AuthService
  ) {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.BsModalService.show(template, {class: 'modal-xl'});
  }

  ngOnInit() {
    this.authService.checkIfUserHasAlreadyCredentialsUpdateAbilities();
  }
}
