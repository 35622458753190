import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modal-green-header-large',
  templateUrl: './modal-green-header-large.component.html',
  styleUrls: ['./modal-green-header-large.component.sass']
})
export class ModalGreenHeaderLargeComponent implements OnInit {
  @Input() modalTitle: string = 'Title';

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

}
