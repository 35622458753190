<ng-container *ngIf="!loadingComments && showCommentsForThisFrame()">
  <div  class="row m-0 py-3 border-dashed-top"
        *ngFor="let comment of filterScenesAndFrames(); let index"
        [ngClass]="{'comment-done': comment.done}">
    <div class="col col-avatar text-center">
      <app-avatar [sizeSmall]="true"></app-avatar>
    </div>
    <div class="col-lg-8">
      <p class="username-and-time mt-2">{{comment.user.name}} {{comment.created_at | date}}</p>
      {{comment.content}}
    </div>
    <div class="col-auto">
      <div class="private-button ml-4">
        <div class="label-wrap mt-2">Private</div>
        <div class="switch-wrap ml-1 mt-1">
          <div class="switch-wrap ml-3">
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="switch-wrap ml-2">
            <ng-container *ngIf="!comment.done">
              <img src="assets/icons/icon_comment_undone.svg"
                   (click)="markCommentAsDone(comment)"
                   class="mouse-pointer"
                   alt="Mark Comment as done"
              />
            </ng-container>
            <ng-container *ngIf="comment.done">
              <img src="assets/icons/icon-done.svg"
                   (click)="markCommentAsDone(comment)"
                   class="mouse-pointer"
                   alt="Mark Comment as undone"
              />
            </ng-container>
            <a (click)="deleteComment(comment)">
              <img src="assets/icons/icon-delete-middle-grey.svg" class="mouse-pointer" alt="Delete comment">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
