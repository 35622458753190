import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {UserService} from "../../../../shared/services/user.service";
import {ProjectTemplateService} from "../../../../shared/services/project-template.service";
import {ClientService} from "../../../../shared/services/client.service";
import {ToastrService} from "ngx-toastr";
import {startWith, switchMap, takeUntil} from "rxjs/operators";
import {interval, Subject, Subscription} from "rxjs";
import {UserGroup} from "../../../../shared/models/userGroup";
import {User} from "../../../../shared/models/user";
import {ProjectTemplate} from "../../../../shared/models/projectTemplate";
import {Client} from "../../../../shared/models/client";
import {ProductService} from "../../../../shared/services/product.service";
import {Product} from "../../../../shared/models/product";
import {ProjectAssetService} from "../../../../shared/services/project-asset.service";
import {FormBuilder, Validators} from "@angular/forms";
import {PollingService} from "../../../../shared/services/polling.service";
import {TabTemplateService} from "../../../../shared/services/tab-template.service";
import {CommonService} from '../../../../shared/services/common.service';

@Component({
  selector: 'app-project-asset-add',
  templateUrl: './project-asset-add.component.html',
  styleUrls: ['./project-asset-add.component.sass']
})
export class ProjectAssetAddComponent implements OnInit, OnDestroy {

  public projectId: string | undefined;

  public ngUnsubscribe = new Subject();

  public userGroup = UserGroup;

  public users: User[] = [];

  public projectTemplates: ProjectTemplate[] = [];

  public clients: Client[] = [];

  public products: Product[] = [];

  public loadingProducts = true;


  public projectAssetForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    product_id: ['', Validators.required],
    budget: ['', Validators.required],
    pms: [''],
    deadline: [''],
    pm_id: ['', Validators.required],
    projectTemplate: [''],
    linkMoco: ['', Validators.pattern(this.commonService.urlRegex)],
    linkHive: ['', Validators.pattern(this.commonService.urlRegex)]
  });

  public timeInterval: Subscription = new Subscription();
  public pollStatus: any;

  public projectAssetId: string = '';

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private projectTemplateService: ProjectTemplateService,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private productService: ProductService,
    private projectAssetService: ProjectAssetService,
    private fb: FormBuilder,
    private pollingService: PollingService,
    private templateService: TabTemplateService,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    const getUserGroup = [this.userGroup.IS_PROJECT_MANAGER];
    this.userService.getUserListByUserGroup(
      getUserGroup
    ).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (response) => {
        this.users = response;
      },
      (error) => {
        this.toastrService.error('Could not load UserGroup List');

      }
    );

    this.productService.getProductList().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (productList) => {
        this.products = productList;
        this.loadingProducts = false;
      },
      (error) => {
        this.toastrService.error('Could not load Products');
      }
    );

    this.templateService.getTabTemplateList(['projectAsset']).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (templates) => {
        this.projectTemplates = templates;
      },
      (error) => {
        this.toastrService.error('Could not load ProjectTemplates');
      }
    );

  }

  filterByUserGroup(userGroupId: string) {
    return this.users.filter((user) => {
      return user.roles?.filter((userRole) => {
        return userRole.id === userGroupId;
      });
    });
  }

  submitProjectAssetForm() {
    if (this.projectId) {
      this.projectAssetService.createProjectAsset(this.projectAssetForm.value, this.projectId).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (successResponse) => {
          this.toastrService.success('Asset added');
          window.location.href = '/projects/detail/' + this.projectId;
        },
        (error) => {

          this.toastrService.error('Could not add Asset');
        }
      );

    } else {
      this.toastrService.error('ProjectId is missing');
    }
  }

  validateSubmitButton() {
    return !this.projectAssetForm.valid;
  }

  get linkHive() {
    return this.projectAssetForm.get('linkHive');
  }

  get linkMoco() {
    return this.projectAssetForm.get('linkMoco');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
