import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommentService} from "../../../services/comment.service";
import { Comment } from "../../../models/comment";
import {CommentsRepository} from "../../../stores/comments/comments.repository";
import {takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";

@Component({
  selector: 'app-comments-view',
  templateUrl: './comments-view.component.html',
  styleUrls: ['./comments-view.component.sass']
})
export class CommentsViewComponent implements OnInit, OnDestroy {

  @Input() inputResource: string | undefined;
  @Input() inputId: string | undefined;
  @Input() projectId!: string;
  @Input() projectAssetId!: string;
  @Input() versionId!: string;
  @Input() projectAssetTabId!: string;
  @Input() videoId!: string;
  @Input() isVideo = false;

  @Output() clickOnTimestamp = new EventEmitter();

  private ngUnsubscribe = new Subject();

  public commentsRepositoryComments: any;

  public comments: Comment[] = [];

  constructor(
    private commentService: CommentService,
    private commentsRepository: CommentsRepository,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.commentsRepositoryComments = this.commentsRepository.comments$.
    subscribe((comments) => {
      this.comments = comments;
    });
  }

  sortCommentsByDate() {

  }

  markCommentAsDone(comment) {
    this.commentService.updateCommentDoneStatus(comment).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (success) => {
        this.toastrService.success('Changed comment status');
        this.commentsRepository.updateSingleEntity(comment.id, 'done', !comment.done);
      },
      (error) => {
        this.toastrService.error('Error while saving comment');
      }
    );
  }

  deleteComment(comment: Comment) {
    if(confirm('Are you sure you want to delete this comment?')) {
      this.commentService.deleteComment(
        comment.id,
        this.projectId,
        this.projectAssetId
      ).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (success) => {
          this.toastrService.success('Comment has been deleted');
          this.commentsRepository.deleteSingleEntity(comment.id);
        },
        (error) => {
          this.toastrService.error('Comment has not been deleted');
        }
      );
    }
  }

  getTimeFormat(seconds) {
    const date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.getMinutes()+':'+date.getSeconds();
  }

  clickOnVideoTimeStamp(value) {
    this.clickOnTimestamp.emit(value);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
