<div class="container-fluid mt-5 text-medium-font text-dark-grey" *ngIf="!loading">
  <div class="row mt-5">
    <div class="col-lg-2">
      <h2 class="text-vvv-green-main mb-4">Project Templates</h2>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-lg-12">
      Full Project Templates e.g. : Explainer Video + 2 Short Videos
    </div>
  </div>
  <div class="row" *ngFor="let projectTemplate of getTemplatesByType('project');">
    <div class="col-lg-10">
      <a [routerLink]="[
      '/projects/',
      'detail',
      projectTemplate.project_id
      ]">
        {{projectTemplate.name}}
      </a>
    </div>
    <div class="col-lg-2">
      <button
        class="vvv-input-small btn btn-primary background-white text-vvv-green-main text-small-font pl-5 pr-5 button-add-template"
        (click)="changeTemplateActiveStatus(projectTemplate)"
      >
        <ng-container *ngIf="projectTemplate.active">
          Set inactive
        </ng-container>
        <ng-container *ngIf="!projectTemplate.active">
          Set active
        </ng-container>
      </button>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-6">
      <h2 class="text-vvv-green-main mb-4">Deliverables / Project Assets</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      Your most used Project Deliverables, Including Tabs, Img Ratio, etc..
    </div>
  </div>
  <div class="row mt-3">
    <ng-container *ngFor="let projectTemplate of getTemplatesByType('projectAsset')">
      <div class="col-lg-3 mb-3">
        <a [routerLink]="[
          '/projects',
          'assets',
          projectTemplate.project_id,
          projectTemplate.projectasset_id,
          'settings'
        ]" target="_blank">
          <div class="card h-100">
            <img src="/assets/img/placeholder-video.svg" title="video"
                 class="card-img-top img-fluid w-100"/>
            <div class="card-body">
              <h4 class="card-title text-dark-grey">
                {{projectTemplate?.project_asset?.name}}
              </h4>
              <div class="card-text">
                <div class="asset-card-product text-uppercase text-vvv-green-main mt-1 mb-2">
                  {{projectTemplate?.project_asset?.products?.name}}
                </div>
                <div class="asset-card-stage">
                  <div class="row mb-2">
                    <div class="col-5 text-uppercase text-dark-grey">
                      Deadline
                    </div>
                    <div class="col-7 text-dark-grey">
                      {{projectTemplate?.project_asset?.config?.deadLine | date}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-5 text-uppercase text-dark-grey">
                      Budget
                    </div>
                    <div class="col-7 text-dark-grey">
                      <ng-container
                        *ngIf="projectTemplate?.project_asset?.budget">{{projectTemplate?.project_asset?.budget}} &euro;
                      </ng-container>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-5 text-uppercase text-dark-grey">
                      PM
                    </div>
                    <div class="col-7 text-dark-grey">
                      <ng-container *ngIf="projectTemplate?.project_asset?.project_asset_manager?.name">
                        {{projectTemplate?.project_asset?.project_asset_manager?.name}}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-container>
  </div>
  <div class="row mt-5">
    <div class="col-lg-6">
      <h2 class="text-vvv-green-main mb-4">Storyboard Templates</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      Your most used Project Deliverables, Including Tabs, Img Ratio, etc..
    </div>
  </div>
  <div class="row mt-3">
    <ng-container *ngFor="let projectTemplate of getTemplatesByType('storyboard')">
      <div class="col-lg-3 mb-3">
        <a [routerLink]="[
          '/projects',
          'assets',
          projectTemplate.project_id,
          projectTemplate.projectasset_id,
          'settings'
        ]" target="_blank">
          <div class="card h-100">
            <img src="/assets/img/placeholder-storyboard.svg" title="video"
                 class="card-img-top img-fluid w-100"/>
            <div class="card-body">
              <h4 class="card-title text-dark-grey">
                {{projectTemplate?.project_asset_tab?.config?.name}}
              </h4>
              <div class="card-text">
                <div class="asset-card-product text-uppercase text-vvv-green-main mt-1 mb-2">
                  {{projectTemplate?.project_asset?.products?.name}}
                </div>
                <div class="asset-card-stage">
                  <div class="row mb-2">
                    <div class="col-5 text-uppercase text-dark-grey">
                      Deadline
                    </div>
                    <div class="col-7 text-dark-grey">
                      {{projectTemplate?.project_asset?.config?.deadLine | date}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-5 text-uppercase text-dark-grey">
                      Budget
                    </div>
                    <div class="col-7 text-dark-grey">
                      <ng-container
                        *ngIf="projectTemplate?.project_asset?.budget">{{projectTemplate?.project_asset?.budget}} &euro;
                      </ng-container>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-5 text-uppercase text-dark-grey">
                      PM
                    </div>
                    <div class="col-7 text-dark-grey">
                      <ng-container *ngIf="projectTemplate?.project_asset?.project_asset_manager?.name">
                        {{projectTemplate?.project_asset?.project_asset_manager?.name}}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-container>
  </div>
</div>
